.backdrop-enter {
  opacity: 0;
}
.backdrop-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.backdrop-exit {
  opacity: 1;
}
.backdrop-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}


.sidebar-enter {
  opacity: 0;
  transform: translateX(100%);
}
.sidebar-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.sidebar-exit {
  opacity: 1;
  transform: translateX(0);
}
.sidebar-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 500ms, transform 500ms;
}

.dropdown-enter {
  height: 0!important;
  padding: 0!important;
}
.dropdown-enter-active {
  height: 270px!important;
  padding: 8px 8px 0 8px!important;
  transition: height 500ms, padding 500ms;
}
.dropdown-exit {
  height: 270px!important;
  padding: 8px 8px 0 8px!important;
}
.dropdown-exit-active {
  height: 0!important;
  padding: 0!important;
  transition: height 500ms, padding 500ms;
}

.dropdownSmall-enter {
  height: 0!important;
  padding: 0!important;
}
.dropdownSmall-enter-active {
  height: 180px!important;
  padding: 8px 8px 0 8px!important;
  transition: height 500ms, padding 500ms;
}
.dropdownSmall-exit {
  height: 180px!important;
  padding: 8px 8px 0 8px!important;
}
.dropdownSmall-exit-active {
  height: 0!important;
  padding: 0!important;
  transition: height 500ms, padding 500ms;
}

.select-enter {
  height: 0!important;
}
.select-enter-active {
  height: 270px!important;
  transition: height 500ms, padding 500ms;
}
.select-exit {
  height: 270px!important;
}
.select-exit-active {
  height: 0!important;
  transition: height 500ms, padding 500ms;
}

.modal_header-enter {
  opacity: 0!important;
}
.modal_header-enter-active {
  opacity: 1!important;
  transition: opacity 500ms;
}
.modal_header-exit {
  opacity: 1!important;
}
.modal_header-exit-active {
  height: 1!important;
  transition: opacity 500ms;
}


.validation_text-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.validation_text-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.validation_text-exit {
  opacity: 1;
  transform: translateY(0);
}
.validation_text-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 500ms, transform 500ms;
}

.tooltip-enter {
  opacity: 0;
}
.tooltip-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.tooltip-exit {
  opacity: 1;
}
.tooltip-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.connection_lost_modal-enter {
  opacity: 0;
  left: -392px;
  transition: opacity 1s, left 500ms;
}
.connection_lost_modal-enter-active {
  opacity: 1;
  left: 40px;
  transition: opacity 1s, left 500ms;
}
.connection_lost_modal-exit {
  opacity: 1;
  left: 40px;
  transition: opacity 500ms, left 500ms;
}
.connection_lost_modal-exit-active {
  opacity: 0;
  left: -392px;
  transition: opacity 500ms, left 500ms;
}

@media (max-width: 768px) and (orientation: portrait) {
  .sidebar-enter {
    opacity: 0;
    transform: translateY(100%);
  }
  .sidebar-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
  .sidebar-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .sidebar-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 500ms, transform 500ms;
  }
}