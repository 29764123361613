@import '../../functions';

.avatars_slider_container{
  position: fixed;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: calc(100% - 320px);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
  font-size: 16px;
  .avatars_slider_inner{
    font-size: px-to-em(32, 16);
    width: 586px;
    min-height: 381px;
    position: relative;
    padding: 10px 0;
    .slider_custom_item{
      padding: 50px 0;
      width: 100%;
      .avatar_name{
        font-size: 1em;
        color: color(White, Solid);
        line-height: px-to-em(38, 32);
        width: 100%;
        text-align: center;
        margin-top: px-to-em(16, 32);
        font-weight: 700;
      }
      .avatar_description{
        width: 100%;
        text-align: center;
        color: color(White, Solid);
        font-size: px-to-em(24, 32);
        line-height: px-to-em(29, 24);
        text-align: center;
        font-weight: 400;
        margin-top: px-to-em(25, 24);
      }
    }
  }
  .slider_buttons{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    &.next_button{
      right: 0;
      .back_button{
        transform: rotate(180deg);
      }
    }
    &.prev_button{
      left: 0;
    }
  }
}