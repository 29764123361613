@import '../../functions';

.tooltip_text{
  --main-color: #{color(Black, Transparent, 400)};
  font-size: 16px;
  padding: px-to-em(5, 16) px-to-em(10, 16);
  min-width: 50px;
  background: var(--main-color);
  color: color(White, Solid);
  position: relative;
  border-radius: px-to-em(4, 16);
  display: inline-block;
  &::after{
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 5px;
  }
  &.top{
    &::after{
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-color: var(--main-color) transparent transparent transparent;
    }
  }
  &.right{
    &::after{
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      border-color: transparent var(--main-color) transparent transparent;
    }
  }
  &.bottom{
    &::after{
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-color: transparent transparent var(--main-color) transparent;
    }
  }
  &.left{
    &::after{
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      border-color: transparent transparent transparent var(--main-color);
    }
  }
}