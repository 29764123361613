@import '../../extends';
@import '../../functions';


.confirm_modal{
  font-size: 16px;
  width: px-to-em(460, 16);
  padding: 1em px-to-em(24, 16) px-to-em(32, 16);
  background: color(White, Solid);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: px-to-em(24, 16);
  .modal_header{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .modal_text_container{
    width: 100%;
    padding: px-to-em(34, 16) 0;
    .modal_text{
      width: 100%;
      color: color(Black, Solid, 400);
      font-weight: 500;
      font-size: 1em;
      text-align: center;
    }
    .sub_text{
      width: 100%;
      display: inline-block;
      text-align: center;
      color: color(Black, Solid, 400);
      font-size: 14px;
      padding-top: 12px;
    }
  }
  .modal_buttons_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal_button{
      width: px-to-em(154, 16);

      &.reject_button{
        margin-right: 1em;
        &:not(.same_button) {
          button {
            @extend %large-negative-outline;
            width: 100%;
          }  
        }
        &.same_button{
          button {
            @extend %large-primary-main;
            width: 100%;
          }
        }
      }
      &.accept_button{
        button{
          @extend %large-primary-main;
          width: 100%
        }
      }
    }
  }
  @media (max-width: 768px) {
    font-size: 14px;
    .modal_buttons_container{
      .modal_button{
        button {
          font-size: 14px!important;
        }
      }
    }
  }
  @media (max-width: 450px) {
    font-size: 10px;
    .modal_buttons_container{
      .modal_button{
        button {
          font-size: 10px!important;
        }
      }
    }
  }
}