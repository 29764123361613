@import '../../functions';

.team_sign{
  font-size: 16px;
  background-color: color(White, Solid);
  width: px-to-em(32, 16);
  height: px-to-em(32, 16);
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.08);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 500ms;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  .logo_iamge{
    width: 70%;
  }
  &.active{
    background: gradient(Secondary, 500);
  }
}