@import '../../functions';
@import '../../extends';

.close_button{
  @extend %small-tertiary-round;
}

.minimize_button{
  @extend %small-tertiary-round;
  .minimize_line{
    display: inline-block;
    width: 16px;
    height: 3px;
    border-radius: 3px;
    background: color(White, Solid);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 500ms;
  }
  &.minimized{
    .minimize_line{
      &:last-child{
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
}

.hex_buttons{
  padding: 0;
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  // opacity: 0;
  svg{
    &:first-child{
      width: 100%;
    }
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 320px){
    transform: scale(.8);
  }
  &.hex_buttons_mobile{
    width: 40px;
    height: 40px;
  }
}

.gradient_buttons{
  // width: calc(100vw /4.5);
  // height: calc(100vw /4.5);
  width: calc(100vw /5);
  height: calc(100vw /5);
  background: linear-gradient(247.2deg, #FFFFFF 5.25%, #E4E6F2 77.64%);
  border: 4px solid #F1F4F8;
  box-shadow: 0px 4px 8px rgba(63, 81, 113, 0.16);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  color: #515355;
  font-weight: bold;
  // font-family: "FiraGO_Bold";
  // font-size: .7em;
  font-size: px-to-rem(16, 16);
  flex-direction: column;
  gap: 2px;
  span{
    display: block;
  }
  &[disabled]{
    opacity: .4;
  }
  &[data-small]{
    width: calc(100vw /6);
    height: calc(100vw /6);
    font-size: .8em;
  }
  &[data-dark]{
    width: calc(100vw /8);
    height: calc(100vw /8);
    background: linear-gradient(247.2deg, #DCDDE6 5.25%, #8E91A6 77.64%);
    border-color: #8E91A6;
    color: #fff;
    span{
      transform: scale(2.5);
    }
  }
  &[data-blue]{ // new Green
    // background: linear-gradient(315deg, #38CCE0 0%, #40D7EC 100%);
    // box-shadow: 0px 4px 8px rgba(57, 194, 214, 0.4);
    // border-color: #39C2D6;
    background: linear-gradient(315deg, #0CCCA6 0%, #0FF5C7 100%);
    box-shadow: 0px 4px 8px rgba(12, 204, 166, 0.4);
    border-color: #0CCCA6;
    color: #fff;
  }
  &[data-purple]{ // New Purple
    // background: linear-gradient(315deg, #A414D7 0%, #C10FFF 100%);
    // box-shadow: 0px 4px 8px rgba(164, 20, 215, 0.4);
    // border-color: #A414D7;    
    background: linear-gradient(315deg, #8B39CC 0%, #AE47FF 100%);
    box-shadow: 0px 4px 8px rgba(139, 57, 204, 0.4);
    border-color: #8B39CC;    
    color: #fff;
    position: absolute;
    left: 1.5rem;
  }
  &[data-pink]{ // new Green
    // background: linear-gradient(315deg, #DD16C9 0%, #FF00E5 100%);
    // border-color: #DD16C9;
    background: linear-gradient(315deg, #B9E800 0%, #D1FF1B 100%);
    box-shadow: 0px 4px 8px rgba(220, 222, 131, 0.4);
    border-color: #B9E800;
    color: #25282B80;
  }
  @media screen and (max-height: 553px) and (max-width: 375px){
    transform: scale(.8);
  }
}

.regular_buttons{
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 1px transparent solid;
  &[disabled]{
    background: rgba(255, 255, 255, 0.32);
    box-shadow: none;
    opacity: .4;
  }
  @media (max-width: 320px){
    transform: scale(.8);
  }
}

.back_button{
  @extend %small-tertiary-round;
}

.edit_button{
  @extend %small-tertiary-round;
}

.delete_button{
  @extend %small-negative-round;
  svg {
    width: 16px;
  }
}

.medium_primary_indicator{
  @extend %chip-indicator;
  width: auto;
  box-shadow: none;
}

.share_button {
  @extend %small-secondary-round;
}

.primary_round{
  @extend %small-primary-round;
}