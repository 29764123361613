@import '../../extends';
@import '../../functions';
.page_container{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background: radial-gradient(74.37% 74.37% at 50% 50%, #FFFFFF 0%, #EBEEF9 100%);
  font-size: 16px;
  .content_container{
    width:  getPercent(846, 1280);
    .image_container{
      width:  100%;
      img {
        width: 100%;
        max-width: 846px;
        margin: 0 auto;
        display: block;
      }
    }
    .text_containers{
      padding: 0 30px;
      .error_title{
        font-size: px-to-em(32, 16);
        color: color(Black, Solid, 400);
        width: 100%;
        text-align: center;
        font-weight: 600;  
      }
      .descritpion{
        font-weight: 400;
        color: color(Black, Transparent, 200);
        font-size: px-to-em(18, 16);
        width: 100%;
        text-align: center;
        margin-top: px-to-em(8, 18);
      }
      .button_container{
        width: 100%;
        margin-top: px-to-em(56, 16);
        button{
          @extend %large-primary-main;
          width: px-to-em(160, 16);
          margin: 0 auto;
          &.with_indicator {
            @extend %large-primary-indicator;
            width: auto;
            padding: 0 30px;
          }
        }
      }
    }
  }
  @media (max-width: 992px){
    @media (orientation: landscape) {
      font-size: 12px;
      .content_container{
        width: getPercent(350, 740);
        .text_containers{
          .button_container{
            margin-top: px-to-em(24, 12);
          }
        }
      }
    }
    @media (orientation: portrait) {
      font-size: 12px;
      .content_container{
        width: 100%;
        .text_containers{
          width: 100%;
          .button_container{
            margin-top: px-to-em(104, 12);
            width: 100%;
            button {
              width: 100%;
              max-width: 312px;
            }
          }
        }
      }
    }
  }
}

.buttons_container{
  display: flex;
  justify-content: center;
  align-items: center;
  .button_container{
    width: auto!important;
    min-width: 180px!important;
    &:last-child{
      margin-left: 15px;
    }
  }
  @media (max-width: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .button_container{
      margin: 0!important;
      margin-top: 20px!important;
      width: 100%!important;
    }
  }
}
