@import '../../extends';
@import '../../functions';

.connection_lost_modal_container{
  font-size: 14px;
  padding: px-to-em(20, 14) px-to-em(27, 14);
  background: color(Warning, Solid, 100);
  border: 1px solid color(Warning, Transparent, 300);
  border-radius: px-to-em(32, 14);
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: px-to-em(73, 14);
  @media (orientation: landscape) and (max-width: 992px) {
    bottom: px-to-em(40, 16);
  }
  left: px-to-em(40, 16);
  z-index: 9999;
  cursor: pointer;
  .offline_icon_container{
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .offline_text{
    font-size: 1em;
    color: color(Black, Transparent, 300);
    font-weight: 400;
    padding: 0 px-to-em(8, 14) 0 1em;
    span{
      font-weight: 700;
      text-decoration: underline;
    }
  }
  .refresh_icon_container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.buzz {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      -webkit-animation-name: buzz-out-on-hover;
    animation-name: buzz-out-on-hover;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
  @media (max-width: 768px) {
    left: 50%;
    bottom: 50px;
    transform: translateX(-50%);
    width: 355px;
  }
  @media (max-width: 360px) {
    width: 320px;
    font-size: 12px;
  }
}


@-webkit-keyframes buzz-out-on-hover {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes buzz-out-on-hover {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}