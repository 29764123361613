@import './functions';
@import './variables';
@import './extends/buttons.scss';
@import './extends/inputs.scss';
@import './extends/searchInput.scss';
@import './extends/selects.scss';
@import './extends/selections.scss';
@import './extends/tabs.scss';
@import './extends/selecionButtons.scss';
@import './extends/chips.scss';

%game-button{
  // background: color(Blue, Solid, 500);
  box-shadow: 0px 2px 4px rgba(24, 96, 163, 0.16);
  border-radius: 24px;
  // border: none;
  font-weight: 500;
  font-size: 12px;
  // // line-Height: 14px;  
  background: linear-gradient(228.88deg, #3561AE 0%, #234785 100%);
  color: #fff;
  border: 4px solid #234785;
  padding: px-to-em(9, 12);
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
  &:disabled{
    cursor: no-drop;
    opacity: 0.5;
  }
}

%pop-up-modal{
  width: 100%;
  background: color(White, Solid);
  min-width: 320px;
  // border-radius: 16px;
  // overflow: hidden;
  position: relative;
  &::before{
    content: "";
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid color(White, Solid, 500);
    top: -16px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

%button-success{
  background: linear-gradient(253.43deg, #45B73B 0%, #37922F 100%);
  border: 2px solid #37922F;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(55, 146, 47, 0.4);
}

%button_round_orange{
  font-size: 16px;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background: linear-gradient(266.1deg, #FFC328 0%, #EB8F3A 100%);
  border: px-to-em(2, 16) solid #E4923C;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(204, 173, 32, 0.32);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}