@import '../../extends';
@import '../../functions';

.popup_modal{
  @extend %pop-up-modal;
  box-sizing: border-box;
  font-size: 16px;
  padding: 1em px-to-em(24, 16);
  border-radius: px-to-em(24, 16);
  // z-index: 100;
  .popup_modal_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .modal_title{
      font-size: px-to-em(18, 16);
      line-height: px-to-em(22, 16);
      color: color(Black, Solid, 400);
      font-weight: 300;
      // text-transform: capitalize;
    }
  }
  .popup_modal_body{
    padding-top: 1em;
    .edit_container{
      width: 100%;
    }
  }
}

.edit_form{
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .edit_inputs{
    width: 100%;          
  }
  .icons_list{
    width: 100%;
    .icons_list_ul{
      display: flex;
      padding: 0;
      list-style-type: none;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .icons_list_li{
        display: flex;
        justify-content: center;
        width: calc(100% / 7);
        margin-top: px-to-em(8, 16);
        .icons_container{
          cursor: pointer;
          width: auto;
        }
      }
    }
  }
  .save_edit_data_container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1em;
    button{
      @extend %small-primary-main;
      width: auto;
      max-width: 10em;
      padding: 1.3em 1.9em;
    }
  }
}

.sidebar_edit{
  .edit_container{
    .edit_form{
      .save_edit_data_container{
        button{
          @extend %large-primary-main;
        }
      }
    }
  }
  @media (max-width: 992px) {
    @media (orientation: landscape) {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .edit_container{
        display: flex;
        width: 272px;
        .edit_form{
          .save_edit_data_container{
            button{
              width: 188px;
              height: 32px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}