%checkbox{
  font-size: 16px;
  width: px-to-em(20);
  height: px-to-em(20);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid color(Black, Transparent, 200);
  transition: all 500ms;
  box-sizing: border-box;
  &.checked{
    border-color: color(Primary, Solid, 500);
    background: color(Primary, Solid, 500);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02);
    svg{
      opacity: 1;
    }
    &:hover{
      border-color: color(Primary, Solid, 600);
      background: color(Primary, Solid, 600);
    }
  }
  &:hover{
    border-color: color(Black, Transparent, 300);
    svg{
      opacity: 1;
    }
  }
  &.disabled{
    opacity: 0.8;
  }
  svg{
    width: 70%;
    opacity: 0;
    transition: all 500ms;
  }
}

%radio{
  font-size: 16px;
  width: px-to-em(20);
  height: px-to-em(20);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid color(Black, Transparent, 200);
  transition: all 500ms;
  box-sizing: border-box;
  position: relative;
  &.checked{
    border-color: color(Primary, Solid, 500);
    background: color(Primary, Solid, 500);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02);
    &::after{
      opacity: 1;
    }
    &:hover{
      border-color: color(Primary, Solid, 600);
      background: color(Primary, Solid, 600);
      &::after{
        background: color(Primary, Solid, 600);
      }  
    }
  }
  &:hover{
    border-color: color(Black, Transparent, 300);
  }
  &.disabled{
    opacity: 0.8;
  }
  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    width: px-to-em(16, 10);
    height: px-to-em(16, 10);
    border-radius: 50%;
    background: color(Primary, Solid, 500);
    transition: all 500ms;
  }
}

%trigger{
  font-size: 16px;
  width: px-to-em(40, 16);
  height: px-to-em(24, 16);
  border: 1px solid color(Black, Transparent, 200);
  background: color(White, Solid, 500);
  border-radius: px-to-em(32, 16);
  position: relative;
  transition: all 500ms;
  cursor: pointer;
  .triggerCircle{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background: color(Black, Transparent, 200);
    border-radius: 50%;
    left: px-to-em(4, 16);
    transition: all 500ms;
  }
  &.active{
    background: color(Positive, Solid, 500);
    border-color: color(Positive, Solid, 500);
    .triggerCircle{
      background: color(White, Solid, 500);
      left: px-to-em(18, 16);
    }
  }
}