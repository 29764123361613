@import '../../functions';

.content{
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow-x: hidden;
  &.landing{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url('../../../../public/images/background/landing-image.jpg');
    .bg_container{
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      img {
        height: 100%;
      }
    }
    &.typeA{
      // background-image: url('../../../../public/images/background/typeA.png');
      .bg_container{
        display: flex;
        justify-content: flex-end;
      }
    }
    &.typeB{
      // background-image: url('../../../../public/images/background/typeB.png');
    }

    .mobile_bg_container{
      // position: fixed;
      width: 100%;
      // height: 100%;
      // left: 0;
      // top: 0;
      display: none;
      img {
        width: 100%;
      }
      .shop_button_container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        button {
          color: color(White, Solid);
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background: linear-gradient(253.43deg, #A4C200 0%, #8AA302 100%);
          padding: px-to-em(15, 16) px-to-em(20, 16);
          border: px-to-em(4, 16) solid #8BA501;
          box-shadow: 0px 4px 16px rgba(138, 163, 2, 0.4);
          border-radius: px-to-em(40, 16);
          width: 135px;
          svg {
            margin-right: 5px;
          }
        }
      }
    }
    @media (max-width: 1024px) and (orientation: portrait){
      // flex-wrap: wrap;
      background-size: 83%;
      background-repeat: no-repeat;
      background-position: top right;
      background-image: url('../../../../public/images/background/landing-image-mobile.jpg');
      .bg_container{
        display: none!important;
      }
      .mobile_bg_container{
        display: block;
      }
      .page_body{
        &.landing{
          // height: unset;
          height: calc(100% - 79px);
          width: 100%;
        }
      }
    }
    @media (max-width: 767px) and (orientation: portrait) {
      background-size: 100%;
    }
    @media (max-width: 375px) {
      background-size: 90%;
    }
  }
  &.noBg {
    background-image: none;
  }
  .page_body{
    height: calc(100% - #{px-to-em(140, 16)});
    padding: px-to-em(30, 16) px-to-em(40, 16) 0;
    // padding-bottom: 100px;
    position: relative;
    // z-index: 2;
    box-sizing: border-box;
    &.landing {
      height: calc(100% - #{px-to-em(116, 16)});
    }
    &.lobbyPage{
      @media (max-width: 600px) and (orientation: landscape) {
        height: 100%!important;
      }
    }
    &.how_to_play{
      @media (max-width: 992px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    @media (max-width: 1023px) {
      &:not(.fixedHeight):not(.landing) {
        height: unset;
      }
      &.fixedHeight {
        height: calc(100% - #{px-to-em(94, 16)});
      }
      padding: px-to-em(15, 16) px-to-em(20, 16) 0;
    }
    @media (max-width: 650px) {
      &.leaderboardPage{
        padding-right: 0;
      }
    }
    @media (max-width: 359px) {
      padding: px-to-em(10, 16) px-to-em(4, 16) 0;
    }
  }
  .confirm_modal_container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  .report_button{
    position: fixed;
    left: 0;
    bottom: 110px;
    background: rgba(37, 40, 43, 0.08);
    // background: black;
    border-radius: 0px 8px 8px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 40px;
    // opacity: 0.26;
    z-index: 9999;
    cursor: pointer;
    transition: all 500ms;
    button{
      // transform: rotate(-90deg);
      // position: absolute;
      // width: 88px;
      background: transparent;
      border: none;
      color: #fff;
      // font-size: 12px;
      font-weight: bold;
      font-family: "FiraGO", sans-serif;
      cursor: pointer;
      // background: red;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
    }
    &:hover {
      width: 40px;
      background: color(Black, Transparent, 200);
      // bottom: 188px;
    }
    @media (max-width: 768px) {
      width: 24px;
      height: 32px;
      button{
        &:hover{
          width: 32px;
        }
      }
    }
  }
  .beta_button{
    position: fixed;
    left: 0;
    bottom: 160px;
    // transform: rotate(-90deg);
    background: rgba(237, 65, 83, 0.8);;
    // background: black;
    border-radius: 0 8px 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 71px;
    // opacity: 0.26;
    z-index: 9999;
    cursor: pointer;
    transition: all 500ms;
    img {
      transform: rotate(90deg);
    }
    button{
      position: absolute;
      background: transparent;
      border: none;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      font-family: "FiraGO", sans-serif;
      cursor: pointer;
      transform: rotate(-90deg);
      width: 71px;
      padding: 0;
    }
    &:hover {
      width: 31px;
      background: color(Negative, Solid);
      // bottom: 188px;
    }
    &.small {
      height: 50px;
      button {
        width: 50px;
      }
    }
  }
  .onbording_setpper_container{
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 999999;
    @media (max-width: 768px) {
      @media (orientation: portrait) {
        right: 0;
        width: 100%;
      }
    }
  }
}

.backdrop-enter{
  opacity: 0;
  width: 0;
  height: 0;
}

.backdrop-enter-active{
  opacity: 1;
  transition: opacity 300ms;
}

.backdrop-exit{
  opacity: 1;
}

.backdrop-exit-active{
  opacity: 0;
  width: 0;
  height: 0;
}

.unAuthorizedBackground {
  background-color: #fff;
}

.special_thanks_outer_container{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.tooltip_container{
  position: absolute;
  left: 0;
  top: 0;
}