@import '../../functions';
@import '../../extends';

.scrollBar::-webkit-scrollbar {
  width: 5px;
  background-color: whitesmoke;
}

/* Track */
.scrollBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px lightgrey; 
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(112,0222,0,0.5);
}
 
/* Handle */
// .scrollBar::-webkit-scrollbar-thumb {
//   background: #FEC129; 
//   border-radius: 10px;
// }

/* Handle on hover */
// .scrollBar::-webkit-scrollbar-thumb:hover {
//   background: #b30000; 
// }

.header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: px-to-em(32, 16) px-to-em(27, 16) 0 px-to-em(27, 16);
  box-sizing: border-box;
  font-size: 16px;
  position: sticky;
  top: 0;
  z-index: 4;
  transition: all 500ms;
  z-index: 10;
  .left_side{
    // font-size: px-to-em(24, 16);
    // line-height: px-to-em(29, 24);
    position: relative;
    // width: 50%;
    a{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .logo{
      width: 100%;
      img {
        // width: 100%;
        width: 350px;
        min-width: 200px;
        @media (max-width: 450px) {
          width: 280px;
        }
        @media (max-width: 360px) {
          width: 230px;
        }
      }
      svg {
        width: 100%;
        max-width: 300px;
      }
    }
    .lobby_name_container{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // overflow: hidden;
      position: relative;
      .lobby_info{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 px-to-em(24, 16) 0 px-to-em(12, 16);
        .lobby_type_image_container{
          width: 2em;//34px
          margin-right: 1em;
          img {
            width: 100%;
          }
        }
        .lobby_name{
          font-size: px-to-em(24, 16);
          color: color(Black, Solid, 400);
          font-weight: bold;
          // text-transform: capitalize;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: px-to-em(400, 24);
          @media (max-width: 1200px) {
            // width: px-to-em(220, 24);
          }
          @media (max-width: 767px) {
            // width: px-to-em(300, 24);
            margin-right: 0;
          }
        }
        @media (max-width: 767px) {
          @media(orientation: portrait) {
            margin-right: 0;
          }
        }
      }
      .button_container{
        @media (orientation: landscape) and (max-width: 992px) {
          display: none;
        }
        margin-right: 1em;
        z-index: 5;
        // overflow: hidden;
        &.edit_game_name{
          position: relative;
        }
        &.share_button{
          position: relative;
          .tooltip_container{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 125%;
          }
        }
        &.red_buttons{
          svg{
            width: 16px;
            height: 16px;
          }
        }
        &:last-child{
          margin-right: 0;
        }
        .lobby_link{
          background: none;
          border: none;
          position: absolute;
          top: -1000%;
          left: -1000%;
        }
        &.details_button{
          display: none;
          .dots{
            display: flex;
            justify-content: center;
            align-items: center;
            .dot {
              display: inline-block;
              width: 4px;
              height: 4px;
              background: #515355;
              border-radius: 50%;
              margin-right: 2px;
              &:last-child{
                margin-right: 0;
              }
            }
          }
        }
      }
    }
    .default_header{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .button_container{
        margin-right: 1em;
        z-index: 5;
        // overflow: hidden;
      }
      .page_name{
        font-size: px-to-em(24, 16);
        color: color(Black, Solid, 400);
        font-weight: bold;
        // text-transform: capitalize;
      }

    }
    @media (max-width: 1023px) {
      font-size: 12px;
    }
    @media (max-width: 850px) {
      .lobby_name_container{
        .lobby_info{
          margin-left: 0!important;
          .lobby_type_image_container{
            display: none;
          }
        }
        // .button_container:not(.details_button, .go_back){
        //   display: none;
        // }
        // .details_button{
        //   display: block!important;
        //   button{
        //     width: 32px!important;
        //     height: 32px!important;
        //   }
        // }
      }
    }
    @media (max-width: 767px) {
      width: 65%;
      @media (orientation: portrait) {
        .lobby_name_container{
          .button_container:not(.details_button, .go_back){
            display: none;
          }
        }
      }
    }
  }
  .header_right_side{
    .authorization_buttons_container{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .auhtorization_button{
        font-size: px-to-em(18, 16);
        font-weight: 500;
        line-height: px-to-em(22, 18);
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        width: px-to-em(192, 18);
        height: px-to-em(64, 18);
        background: #EEEEEE;
        border-radius: px-to-em(12, 18);
        border: none;
        cursor: pointer;
        transition: all 500ms;
        margin-right: px-to-em(16, 18);
        &:hover{
          background: #333333;
          color: #FFFFFF;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .authorized_header{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      .header_menu_buttons{
        display: flex;
        align-items: center;
        margin-right: px-to-em(30, 16);
        .header_menu_button{
          .hex{
            width: px-to-em(42, 16);
            height: px-to-em(46, 16);
            svg {
              path {
                transition: all 500ms;
              }
            }
          }
          margin-right: px-to-em(16, 16);
          // width: px-to-em(48, 16);
          // height: px-to-em(48, 16);
          // background: linear-gradient(266.1deg, #FFC328 0%, #EB8F3A 100%);
          // border: 2px solid #E4923C;
          // box-shadow: 0px 4px 16px rgba(204, 173, 32, 0.32);
          // border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: relative;
          // background: url('/images/common/hederNavBack.png');
          &.notifications{
            position: relative;
            .notification_modal_container{
              position: absolute;
              // width: 200px;
              // transform: translateX(-55%);
              top: 70px;
              z-index: 3;
            }
          }

          img {
            pointer-events: none;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          &:last-child{
            margin-right: 0;
          }
          @media (max-width: 1024px) {
            margin-right: px-to-em(15, 16);
          }
        }
        &:hover{
          .hex:not(.red){
            svg {
              path {
                transition: all 500ms;
                fill: linear-gradient(266.1deg, #ECB017 0%, #DC7D26 100%);
                stroke: #D37F27;
              }
            }
          }
        }
      }
      .user_information{
        display: flex;
        align-items: center;
        padding: px-to-em(8, 16);
        background: color(White, Transparent, 300);
        border-radius: px-to-em(32, 16);
        width: px-to-em(200, 16);
        .header_avatar{
          margin-right: px-to-em(14, 16);
        }
        .user_name{
          font-size: 1em;
          line-height: px-to-em(19, 16);
          color: color(Black, Solid, 400);
          font-weight: 600;
          position: relative;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
          .user_menu{
            opacity: 0;
            transition: all 500ms;
            position: absolute;
            width: auto;
            padding: 0;
            width: 200px;
            right: 0;
            display: flex;
            flex-wrap: wrap;
            bottom: -85px;
            li{
              display: inline-block;
              padding: 10px;
              width: 100%;
              background: #e2e2e2;
              border-bottom: 1px solid black;
            }
          }
          &:hover{
            .user_menu{
              opacity: 1;
            }
          }
        }
      }
      .responsive_header{
        display: none;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        .hex{
          width: px-to-em(42, 16);
          height: px-to-em(46, 16);
        }
        img {
          cursor: pointer;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      @media (max-width: 767px) {
        .header_menu_buttons{
          visibility: hidden;
          width: 0;
          height: 0;
          overflow: hidden;
        }
        .user_information{
          display: none;
        }
        .responsive_header{
          display: block;
        }
      }
      .unread_notif{
        position: absolute;
        font-size:  px-to-em(10, 16);
        top: -0.5em;
        right: -0.5em;
        line-height: px-to-em(12, 10);
        width: px-to-em(20, 10);
        height: px-to-em(20, 10);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(225deg, #ED4153 0%, #BE3442 100%);;
        color: white;
      }
    }
  }
  
  .header_nav{
    width: 100%;
    .nav_ul{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0;
      .nav_li{
        display: inline-block;
        font-size: 1em; // 16px
        margin-right: px-to-em(40, 16);
        color: color(Black, Solid, 400);
        line-height: px-to-em(19, 16);
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg {
          margin-right: px-to-em(8, 16);
        }
        span{
          cursor: pointer;
        }
        &.non_standart{
          svg {
            margin-right: px-to-em(8, 16);
          }
          color: color(White, Solid);
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background: linear-gradient(253.43deg, #A4C200 0%, #8AA302 100%);
          padding: px-to-em(15, 16) px-to-em(20, 16);
          border: px-to-em(4, 16) solid #8BA501;
          box-shadow: 0px 4px 16px rgba(138, 163, 2, 0.4);
          border-radius: px-to-em(40, 16);
        }
        &.hex_header{
          display: none;
          .header_menu_button{
            .hex{
              width: 46px;
              height: 49px;
              svg {
                margin-right: 0;
                path {
                  transition: all 500ms;
                }
              }
            }
            margin-right: unset!important;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;
            // background: url('/images/common/hederNavBack.png');
            &.notifications{
              position: relative;
              .unread_notif{
                position: absolute;
                font-size:  px-to-em(10, 16);
                top: -0.5em;
                right: -0.5em;
                line-height: px-to-em(12, 10);
                width: px-to-em(20, 10);
                height: px-to-em(20, 10);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: linear-gradient(225deg, #ED4153 0%, #BE3442 100%);;
                color: white;
              }
              .notification_modal_container{
                position: absolute;
                // width: 200px;
                // transform: translateX(-55%);
                top: 70px;
                z-index: 3;
              }
            }
            img {
              cursor: pointer;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            @media (max-width: 1024px) {
              margin-right: px-to-em(15, 16);
            }
          }
        }
        &:last-child{
          margin-right: 0;
        }
        .languages_container{
          width: 120px;
          height: 40px;
          z-index: 1;
          font-size: 1em;
          font-weight: 600;
          &>div{
            height: 100%!important;
            background: transparent;
            &>div:first-child{
              background: transparent;
              height: 100%!important;
            }
            &>div:nth-child(2){
              // background: transparent;
            }
          }
        }
      }
    }
  }

  &.onboardingInProgress{
    position: relative;
    z-index: unset;
  }
  &.scrolled {
    background: radial-gradient(74.37% 74.37% at 50% 50%, #FFFFFF 0%, #EBEEF9 100%);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    // padding: px-to-em(12, 16);
  }

  @media (max-width: 1024px) {
    padding: px-to-em(12, 16) px-to-em(12, 16) 0 px-to-em(12, 16);
  }
  @media (max-width: 1024px) and (orientation: portrait) {
    .header_right_side{
      .header_nav{
        .nav_ul{
          .nav_li{
            // display: none;
          }
        }
      }
    }
    // &.not_authorized{
    //   position: absolute;
    //   width: 100%;
    //   top: 0;
    //   left: 0;
    // }
  }
  @media (max-width: 1023px) {
    padding: 0.75em 1em 0 0.75em;
    .left_side{
      img.logo {
        max-width: 210px;
      }
    }
    .header_right_side{
      .authorized_header{
        .user_information {
          .header_avatar{
            & > div {
              & > div{
                width: 32px;
                height: 32px;
              }
            }  
          }
        }
        .header_menu_buttons{
          margin-right: px-to-em(16);
          .header_menu_button{
            margin-right: px-to-em(10);
            .hex{
              width: px-to-em(32, 16);
              height: px-to-em(35, 16);
              svg{
                width: 100%;
              }
            }
            img {
              width: 40%;
            }
          }
        }
      }
      .header_nav{
        .nav_ul{
          .nav_li{
            margin-right: 1em;
            font-size: 0.8em;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .header_right_side{
      .header_nav{
        .nav_ul{
          .nav_li{
            &:not(.non_standart) {
              display: none;
            }
            &.hex_header{
              display: inline-block;
              @media (orientation: portrait) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 375px){
    .left_side{
      .logo{
        svg {
          width: 240px!important;
          height: 50px!important;
        }
      }
    }
  }
}

.responsive_navigation{
  width: 100%;
  font-size: 16px;
  .navigation_ul{
    color-scheme: light;
    width: 100%;
    padding: 0 0 px-to-em(105) 0;
    list-style-type: none;
    .navigation_li{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: px-to-em(20, 16);
      .navigation_main_cont{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .navigation_image_container{
          width: px-to-em(32, 16);
          margin-right: 1em;
          &:not(.resp_avatar_cont){
            height: px-to-em(32, 16);
            border-radius: 50%;
            background: color(Black, Transparent, 25);
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02);
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .navigation_text{
          font-size: 1em;
          font-weight: 500;
          color: color(Black, Solid, 400)
        }
      }
      .navigation_indicator{
        .unread_indicator{
          display: inline-block;
          width: px-to-em(24, 10);
          height: px-to-em(24, 10);
          background: gradient(Negative, 500);
          box-shadow: 0px 2px 4px rgba(190, 52, 66, 0.32);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: px-to-em(10, 16);
          color: color(White, Solid);
          border-radius: 50%;
        }
      }
    }
  }
  .logout_button_container {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background: color(White, Solid);
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    padding: 1.5em;
    box-sizing: border-box;
    z-index: 1;
    .user_actions{
      @extend %large-primary-main;
    }
    .logout_button{
      @extend %large-negative-outline;
      background: transparent;
      // text-transform: capitalize;
      margin: 0 auto;
    }
  }
  @media (max-width: 768px) {
    @media (orientation: landscape) {
      height: 100%;
      .navigation_ul {
        height: calc(100% - 82px);
        // overflow-y: auto;
        overflow-y: scroll;
        padding-bottom: 0;  
      }
      .logout_button_container{
        padding: 0.5em;
      }
    }
  }
}
.unAuthorized_header_container{
  width: 100%;
  font-size: 16px;
  padding-bottom: 155px;
  .unAuthorized_header_ul{
    width: 100%;
    padding: 0;
    list-style-type: none;
    .navigation_li{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .languages_container{
        width: 162px;
        height: 40px;
        position: relative;
        z-index: 2;
        margin-top: 20px;
        &>div{
          &>div:not(:first-child){
            height: 162px;
          }
        }
      }
      span{
        display: inline-block;
        width: 100%;
        font-size: 1em;
        padding: px-to-em(15, 16) 0;
        color: color(Black, Solid, 400)
      }
      &> svg {
        margin-right: 0.5em;
      }
    }
  }
  .buttons_container{
    width: 100%;
    padding: px-to-em(24, 16) 0 ;
    position: absolute;
    bottom: 0;
    left: 0;
    @media (orientation: landscape) and (max-width: 768px) {
      top: 50%;
      left: 50%;
      transform: translate(-40%, -50%);
    }
    .button_container{
      width: 100%;
      &.log_in_container{
        button{
          @extend %large-primary-outline;
          width: 100%;
          max-width: 312px;
          margin: 0 auto;
        }
      }
      &.sign_up_container{
        button{
          @extend %large-primary-main;
          width: 100%;
          max-width: 312px;
          margin: 0 auto;
        }
      }
      &:last-child{
        margin-top: 1em;
      }
    }
  }
}

.editGame_container{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50px;
}

.mainLink  {
  color: transparent;
}

.confirm_modal_container{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}