@import "../../extends";
@import "../../functions";

.modal_holder_parent{
  width: 100vw;
  height: 100vh;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  .modal_holder {
    font-size: 24px;
    display: inline-block;
    // padding: px-to-em(5, 24);
    border: 1px solid black;
    border-radius: px-to-em(32, 24);
    background: color(White, Solid);
    // transform: translate(50%, 50%);
    // bottom: 50%;
    // right: 50%;
    // position: fixed;
    // z-index: 3;
    transition: all 500ms;
    border: px-to-em(4, 24) solid color(Black, Transparent, 50);
    box-sizing: border-box;
    position: relative;
    // z-index: 998;
    &.rematch-modal {
      width: 638px;
    }
    .modal_head_image{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: getPercent(80, 800);
      box-sizing: border-box;
      border: 6px solid;
      border-radius: 50%;
      position: absolute;
      min-width: 64px;
      &.classic{
        background: color(Blue, Solid, 400);
        border-color: color(Primary, Solid, 500);
      }
      &.hero{
        background: color(Red, Solid, 400);
        border-color: color(Red, Solid, 500);
      }
      &.team_talk{
        background: color(Yellow, Solid, 400);
        border-color: color(Yellow, Solid, 500);
      }
      &.private{
        background: color(Green, Solid, 400);
        border-color: color(Green, Solid, 500);
      }
      &.any{
        // background: red;
        border-color: #E4923C;
      }
      img{
        width: 100%;
        height: 100%;
        position: absolute;
      }
      .temporary_text {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: white;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &::after{
        content: '';
        display: block;
        padding-top: 100%;
      }
    }
  
    .modal_header {
      display: flex;
      padding: px-to-em(60, 24) 0 px-to-em(10, 24);
      justify-content: flex-end;
      .responsive_avatar{
        display: none;
        box-sizing: border-box;
        border: 3px solid;
        border-radius: 50%;
        position: relative;
        min-width: 64px;
        margin-right: 10px;
        &.classic{
          background: color(Blue, Solid, 400);
          border-color: color(Primary, Solid, 500);
        }
        &.hero{
          background: color(Red, Solid, 400);
          border-color: color(Red, Solid, 500);
        }
        &.team_talk{
          background: color(Yellow, Solid, 400);
          border-color: color(Yellow, Solid, 500);
        }
        &.private{
          background: color(Green, Solid, 400);
          border-color: color(Green, Solid, 500);
        }
        img{
          width: 100%;
          height: 100%;
          position: absolute;
        }
        &::after{
          content: '';
          display: block;
          padding-top: 100%;
        }
      }
      .modal_title {
        width: 100%;
        text-align: center;
        font-size: 1em; //24px
        font-weight: 700;
        line-height: px-to-em(24, 29);
        color: color(Black, Solid, 400);
      }
      button {
        @extend %button_round_orange;
        position: absolute;
        top: 1em;
        right: px-to-em(32, 24);
      }
    }
    &.minimized {
      bottom: 5%;
      right: 5%;
      transform: translate(0, 0);
      border: none;
      background: transparent;
      width: unset;
      height: unset;
      position: absolute;
      .modal_header{
        padding: 0;
        z-index: 1;
        button{
          z-index: 1;
          opacity: 0;
          transition: all 500ms;
        }
      }
      .common_modal{
        width: 264px;
        height: 92px;
        cursor: pointer;
        box-sizing: border-box;
        // font-size: 12px;
        &.searchingGame{
          background: color(Warning, Solid, 100);
          border: 4px solid color(Warning, Transparent, 200);
        }
        &.gameFound{
          border: 4px solid color(Positive, Transparent, 100);
          background: color(Positive, Solid, 100);
        }
        .searching_game{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          .searching_progress{
            padding: 0;
            font-size: 12px;
            position: relative;
            z-index: 1;
            .content{
              .image_container{
                width: px-to-em(40, 12);
              }
              .progress_indicator{
                background: none;
                border: none;
                margin-top: 0;
                padding: px-to-em(6, 12);
                min-width: unset;
              }
            }
          }
          .searching_bottom{
            position: absolute;
            background: transparent;
            height: unset;
            bottom: 5px;
            opacity: 0;
            transition: all 500ms;
            .leave_button{
              @extend %small-negative-outline;
              background: transparent;
            }
            .join_button{
              @extend %small-primary-main;
            }
          }
        }
      }
      &:hover{
        .modal_header{
          padding: 0;
          button{
            opacity: 1;
          }
        }
        .common_modal {
          height: 152px;
          .searching_game{
            .searching_bottom{
              opacity: 1;
            }
          }
        }
      }
      @media (max-width:768px) {
        width: unset;
        height: unset;
        border-radius: inherit;
      }
    }
    @media (max-width:768px),
    (max-width:768px) and (max-aspect-ratio: 169/126),
    (max-width: 1023px) and (orientation: landscape){
      width: 100%;
      height: 100%;
      border-radius: 0;
      .modal_head_image{
        display: none;
      }
      .modal_header{
        padding-top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .modal_title{
          width: auto;
        }
        .responsive_avatar{
          display: unset;
        }
      }
    }
  }
}


.common_modal{
  // min-width: 450px;
  background: white;
  font-size: 24px;
  // width: 100%;
  // max-width: 900px;
  // width: 46.87500vw;
  width: 900px;
  max-width: px-to-em(900, 24);
  // height: px-to-em(650, 25);
  border-radius: px-to-em(32, 24);
  overflow: hidden;
  transition: all 500ms;
  position: relative;
  .common_modal_content{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &::after{
    content: "";
    display: block;
    padding-bottom: getPercent(625, 900);
  }
  .modal_title{
    width: 100%;
    text-align: center;
    color: color(Black, Solid, 400);
    font-size: 1em;
    line-height: px-to-em(29, 24);
    font-weight: 700;
    padding: px-to-em(50, 24) 0 px-to-em(10, 24) 0;
  }
  .wizard_setting_box{
    width: 100%;
    height: 100%;
    position: relative;
    .wizard_setting_box_title{
      font-size: px-to-em(18, 24);
      width: 100%;
      text-align: center;
      font-weight: 300;
      color: color(Black, Solid, 400);
      line-height: px-to-em(22, 18);
      margin-bottom: px-to-em(25, 18 );
    }
    .wizard_bottom_side{
      position: absolute;
      bottom: 0;
      left: 0;
      // transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: color(Black, Transparent, 50);
      height: 100px;
      // &::after{
      //   content: "";
      //   position:absolute;
      //   top:-40px;
      //   left:0;
      //   height:40px;
      //   width: 40px;
      //   border-bottom-left-radius: 50%;
      //   background: color(White, Solid);
      //   // box-shadow: 0 20px 0 0 #522d5b;
      // }
      .player_select_text{
        position: absolute;
        left: 5%;
        bottom: 50%;
        transform: translateY(50%);
        margin: 0;
        font-size: px-to-em(18, 24);
        font-weight: 400;
        color: color(Black, Solid, 400);
        span{
          font-weight: 700;
        }
      }
      .chosen_settings{
        display: flex;
        justify-content: center;
        align-items: center;
        .chosen_setting{
          width: auto;
          // width: px-to-em(60, 24);
          // height: px-to-em(95, 24);
          position: relative;
          .chosen_setting_text_top{
            // width: 100%;
            text-align: center;
            font-size: px-to-em(12, 24);
            font-weight: 400;
            color: color(Secondary, Solid, 600);
            line-height: px-to-em(14, 12);
            transition: all 250ms;
            // text-transform: capitalize;
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            font-weight: 700;
          }
          .chosen_setting_circle{
            display: flex;
            justify-content: center;
            align-items: center;
            width: px-to-em(40, 24);
            height: px-to-em(40, 24);
            border: px-to-em(4, 24) solid color(Secondary, Solid, 600);
            background: color(Primary, Solid, 100);
            border-radius: 50%;
            box-sizing: border-box;
            background: #EEEEEE;
            // margin: px-to-em(8, 25) auto;
            .inner_circle{
              width: 0;
              height: 0;
              background: gradient(Secondary, 500);
              transition: all 250ms;
            }
          }
          .chosen_setting_text_bottom{
            // width: 100%;
            text-align: center;
            font-size: px-to-em(14, 24);
            font-weight: 700;
            line-height: px-to-em(17, 14);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            color: color(Black, Solid, 400);
            // text-transform: capitalize;
          }
          &.active{
            .chosen_setting_text_top{
              font-weight: 700;
              color: color(Black, Solid, 300);
              font-weight: normal;
            }
            .chosen_setting_circle{
              box-shadow: 0px 4px 16px rgba(138, 163, 2, 0.4);
              // background: gradient(Secondary, 500);
              .inner_circle{
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
          }
        }
        .wizard_step{
          width: px-to-em(40, 24);
          height: px-to-em(95, 24);
          display: flex;
          justify-content: center;
          align-items: center;
          .wizard_step_inner{
            width: px-to-em(25, 24);
            height: px-to-em(8, 24);
            background: #CDCDCD;
            position: relative;
            &::after{
              content: '';
              width: 0;
              height: 100%;
              display: inline-block;
              position: absolute;
              left: 0;
              top: 0;
              background: color(Secondary, Solid, 600);
              transition: all 500ms;
            }
          }
          &.active{
            .wizard_step_inner{
              &::after{
                width: 100%;
              }
            }
          }
        }
        @media (max-width: 600px) {
          font-size: 18px;
        }
      }
      .search_game_holder{
        position: absolute;
        right: 5%;
        bottom: 50%;
        transform: translateY(50%);
        margin: 0;
        width: px-to-em(178, 24);
        background: gradient(Primary, 500);
        border-radius: px-to-em(40, 24);
        button {
          @extend %large-primary-main;
          width: 100%;
        }
      }
    }
    .wizard_setting_box_buttons{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 auto;
      // height: px-to-em(400, 24);
      height: calc(100% - 150px - #{px-to-em(47, 24)});
      overflow-y: auto;
      // width: px-to-em(672, 24);
      .splitted_content{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:first-child{
          align-self: flex-end;
        }
        &:last-child{
          margin-top: px-to-em(16, 24);
          align-self: flex-start;
        }
      }
      .responsive_cont{
        display: none;
        width: 100%;
        // display: flex;
        justify-content: center;
        align-items: center;
      }
      .wizard_setup_button{
        background: #EEEEEE;
        transition: all 500ms;
        cursor: pointer;
        background: linear-gradient(247.2deg, #FFFFFF 5.25%, #E4E6F2 77.64%);
        // border: 4px solid #F1F4F8;
        box-sizing: border-box;
        box-shadow: 0px 4px 8px rgba(63, 81, 113, 0.08);
        border-radius: px-to-em(24, 32);
        overflow: hidden;
        position: relative;
        .content{
          background: linear-gradient(247.2deg, #FFFFFF 5.25%, #E4E6F2 77.64%);
          border-radius: px-to-em(24, 32);
          border: 4px solid #F1F4F8;
          box-sizing: border-box;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          .top_side{
            width: 100%;
            height: 45%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            img {
              width: getPercent(64, 240);
              &.game_modes{
                width: auto;
              }
            }
          }
          .bottom_side{
            width: 100%;
            height: 55%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            .title{
              width: 100%;
              margin-top: getPercent(20, 240);
              text-align: center;
              font-size: 1em; //24px
              line-height: px-to-em(29, 24);
              font-weight: bold;
              color: color(Black, Solid, 400);
              text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
            }
            .description{
              font-size: px-to-em(12, 24);
              line-height: px-to-em(14, 12);
              color: color(Black, Solid, 300);
              margin-top: px-to-em(6, 12);
            }
          }
          .player_number{
            font-size: px-to-em(32, 24);
            line-height: px-to-em(38, 32);
            color: color(Black, Solid, 400);
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
            font-weight: bold;
          }
        }
        &:hover{
          .content{
            background: gradient(Light, 600);
            box-shadow: 0px 4px 8px rgba(63, 81, 113, 0.08);
          }
          // border-color:#234785;
        }
        &::after{
          content: "";
          display: block;
        }
        &.active{
          background: gradient(Primary, 500);
          .content{
            border-color:#234785;
            background: gradient(Primary, 500);
            box-shadow: 0px 4px 8px rgba(63, 81, 113, 0.08);
            .bottom_side{
              .title{
                color: color(White, Solid, 500);
              }
              .description{
                color: color(White, Transparent, 400);
              }
            }
          }
          .player_number{
            color: color(White, Solid, 500);
          }
        }
        &.large{
          width: px-to-em(320, 32);
          height: px-to-em(300, 32);
        }
        &.medium{
          width: getPercent(240, 800);
          margin-right: getPercent(19, 800);
          &::after{
            padding-top: getPercent(200, 240);
          }
        }
        &.small{
          width: getPercent(146, 800);
          margin-right: getPercent(16, 800);
          &::after{
            padding-top: getPercent(122, 146);
          }
        }
        &.rules{
          width: getPercent(208, 800);
          margin-right: getPercent(16, 800);
          .content{
            .top_side{
              height: 35%;
            }
            .bottom_side{
              height: 65%;
              .title{
                font-size: px-to-em(18, 24);
                line-height: px-to-em(22, 18);
                padding: 0 px-to-em(5, 25);
                margin-top: 0;
              }
            }
          }
          &::after{
            padding-top: getPercent(168, 208);
          }
        }
        &:last-child{
          margin-right: 0;
        }
      }
      &.player_select{
        padding: 0 px-to-em(88, 24);
        box-sizing: border-box;
        height: calc(100% - 100px - #{px-to-em(45, 24)});
        .player_select_ul{
          width: 100%;
          list-style-type: none;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
          overflow-y: auto;
          padding-top: px-to-em(10, 24);
          padding-left: 0;
          height: 100%;
          .player_select_li{
            width: calc(100% / 6);
            margin-top: px-to-em(20, 24);
            .select_friend_container{
              cursor: pointer;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              // width: calc((100% - 5 * #{px-to-em(30, 24)}) / 6);
              margin-right: px-to-em(45, 24);
              &:last-child{
                margin-right: 0;
              }
              span{
                display: inline-block;
                width: 100%;
                font-size: px-to-em(14, 24);
                margin-top: px-to-em(8, 14);
                color: color(Black, Solid, 400);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
              }
            }
          }
        }
        @media (max-width: 1366px) {
          .player_select_ul{
            .player_select_li{
              width: calc(100% / 5);
            }
          }
        }
        @media (max-width: 600px) {
          padding: 0 px-to-em(30, 24);
          height: calc(100% - 78px - 47px);
          .player_select_ul{
            .player_select_li{
              width: calc(100% / 4);
            }
          }
        }
        @media (max-width: 450px) {
          .player_select_ul{
            .player_select_li{
              width: calc(100% / 3);
            }
          }
        }
      }
    }
    .form_group{
      width: px-to-em(272, 24);
      margin: auto;
      height: calc(100% - 100px - #{px-to-em(45, 24)});
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &.minimized{
    width: 264px;
    height: 92px;
    cursor: pointer;
    // font-size: 12px;
    &.searchingGame{
      background: color(Warning, Solid, 100);
      border: 4px solid color(Warning, Transparent, 200);
    }
    .searching_game{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .searching_progress{
        padding: 0;
        font-size: 12px;
        position: relative;
        z-index: 1;
        .content{
          .image_container{
            width: px-to-em(40, 12);
          }
          .progress_indicator{
            background: none;
            border: none;
            margin-top: 0;
            padding: px-to-em(6, 12);
            min-width: unset;
          }
        }
      }
      .searching_bottom{
        position: absolute;
        background: transparent;
        height: unset;
        bottom: 5px;
        opacity: 0;
        transition: all 500ms;
        .leave_button{
          @extend %small-negative-outline;
          background: transparent;
        }
      }
    }
    &:hover{
      height: 152px;
      .searching_game{
        .searching_bottom{
          opacity: 1;
        }
      }
    }
  }
  @media (max-width: 1440px) {
    width: 70vw;
  }
  @media (max-width: 1146px) {
    width: 80vw;
  }
  @media (max-width: 820px) {
    width: 85vw;
  }
  @media (max-width: 1023px) and (orientation: landscape) {
    .wizard_setting_box{
      .wizard_setting_box_title{
        margin-bottom: 0.5em;
      }
      .wizard_setting_box_buttons{
        height: calc(100% - 122px)!important;
        .splitted_content{
          display: none;
        }
        .responsive_cont{
          display: flex!important;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          .button_cont{
            width: calc(100% / 5);
            max-width: 144px;
            margin: 0;
            height: 100%;
            padding: 0 8px;
            max-height: 150px;
            .wizard_setup_button{
              width: 100%;
              height: 100%;
            }
          }
        }
        .wizard_setup_button{
          max-width: 170px!important;
          .content{
            .bottom_side{
              .title{
                font-size: 18px;
              }
            }
          }
        }
        &.rules {
          .wizard_setup_button{
            .content{
              .bottom_side{
                .title{
                  font-size: 14px;
                }
                .description{
                  text-align: center;
                }
              }
            }
          }
        }
      }
      .wizard_bottom_side{
        height: 90px!important;
        .wizard_step{
          height: 90px!important;
        }
        .player_select_text{
          left: 2%!important;
        }
        .search_game_holder {
          right: 2%;
          width: 5.5em;
          .search_game{
            font-size: 14px;
          }
        }
      }
    }
  }
  @media (max-width: 992px) and (orientation: portrait) {
    .wizard_setting_box{
      .wizard_bottom_side{
        .player_select_text{
          left: 2%;
        }
        .search_game_holder{
          right: 2%;
          width: 5.5em;
          .search_game{
            font-size: 14px;
          }
        }
      }
    }
  }
  @media (max-width: 768px),
  (max-width:768px) and (max-aspect-ratio: 169/126),
  (max-width: 1023px) and (orientation: landscape){
    width: 100%;
    height: calc(100% - #{px-to-em(74, 24)});
    max-width: unset;
    transition: unset;
    &:not(.minimized) {
      border-radius: 0;
      overflow-y: auto;
    }
    &::after{
      display: none;
    }
  }
  @media (max-width: 600px) {
    .wizard_setting_box{
      .wizard_setting_box_buttons {
        &.game_mode{
          flex-direction: column;
          justify-content: center;
          align-items: center;  
          .wizard_setup_button{
            width: 50%;
            margin-right: 0;
            &:last-child{
              margin-top: 10px;
            }
          }
        }
        .splitted_content{
          display: none;
        }
        .responsive_cont{
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          .button_cont{
            width: 50%;
            padding: 2% 5%;
            box-sizing: border-box;
            .wizard_setup_button{
              width: 100%;
            }  
          }
        }
      }

      .wizard_bottom_side{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: unset;
        .player_select_text{
          position: unset;
          width: 100%;
          transform: unset;
          text-align: center;
        }
        .chosen_settings{
          width: 100%;
        }
        .search_game_holder{
          position: unset;
          // width: 100%;
          transform: unset;
        }
      }
    } 
  }
}

.searching_game{
  width: 100%;
  font-size: 1em;
  overflow: hidden;
  height: 100%;
  position: relative;
  .mm_settings_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: px-to-em(40, 24);
    box-sizing: border-box;
    .setting_box{
      margin-left: 1em; // 24px
      .setting_box_title{
        font-size: px-to-em(16, 24);
        line-height: px-to-em(19, 16);
        color: color(Black, Solid, 400);
        font-weight: normal;
        text-align: center;
      }
      .setting_box_buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: px-to-em(8, 24);
        .setup_button{
          font-size: px-to-em(16, 24);
          background: linear-gradient(266.1deg, rgba(252, 252, 252, 0.58) 0%, rgba(239, 239, 239, 0.58) 100%);
          border: px-to-em(2, 16) solid #EFEFEF;
          box-sizing: border-box;
          box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.04);
          border-radius: px-to-em(40, 16);
          display: flex;
          justify-content: center;
          align-items: center;
          height: px-to-em(48, 16);
          border-radius: 50%;
          margin-right: px-to-em(8, 16);
          box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.04);
          &.wide{
            width: px-to-em(80, 16);
            border-radius: px-to-em(40, 16);
          }
          &.round{
            width: px-to-em(48, 16);
            img {
              width: px-to-em(30, 16);
            }
          }
          &:last-child{
            margin-right: 0;
          }
        }
      }
      &:first-child{
        margin-left: 0;
      }
    }
    @media (max-width: 600px) {
      flex-wrap: wrap;
      .setting_box {
        &.rules_box {
          margin-top: 10px;
          @media (max-width: 600px) {
            width: 100%;
          }    
        }
      }
    }
  }
  .searching_progress{
    font-size: px-to-em(16, 24);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: px-to-em(76, 16);
    .content{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .image_container{
        width: px-to-em(104, 16);
        img {
          width: 100%;
        }
      }
      .progress_indicator{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-width: px-to-em(256, 16);
        padding: px-to-em(16, 16);
        box-sizing: border-box;
        border-radius: px-to-em(20, 16) px-to-em(20, 16) px-to-em(20, 16) 0;
        border: px-to-em(2, 16) solid;
        margin-top: px-to-em(10, 16);
        .progress_loader{
          width: px-to-em(48, 16);
          height: px-to-em(48, 16);
          margin-right: px-to-em(16, 16);
          .game_found{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: color(Green, Transparent, 50);
          }
        }
        .progress_text{
          width: auto;
          .upperr_text{
            font-size: 1em;
            line-height: px-to-em(19, 16);
            color: color(Black, Solid, 400);
            font-weight: 500;
          }
          .bottom_text{
            font-size: px-to-em(14, 16);
            line-height: px-to-em(17, 14);
            color: color(Black, Solid, 400);
            font-weight: 300;
          }
        }
        &.searching{
          background: color(Warning, Solid, 100);
          border-color: color(Warning, Transparent, 200);
        }
        &.found{
          background: color(Positive, Solid, 100);
          border-color: color(Positive, Transparent, 200);
        }
      }
    }
    @media (max-width: 600px) {
      .content{
        align-items: flex-end;
        .image_container{
          width: px-to-em(50, 16);
          margin-right: px-to-em(12, 16);
        }
        .progress_indicator{
          margin-top: 0;
          padding: px-to-em(8, 16);
        }
      }
    }
  }
  .searching_bottom{
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: color(Black, Transparent, 50);
    .leave_button{
      @extend %large-negative-outline;
    }
    .join_button{
      @extend %large-primary-main;
    }
  }
  @media (max-height: 530px) and (orientation: landscape) {
    .searching_progress{
      padding-top: 2.75em;
      .content{
        .image_container{
          width: 50px;
        }
        .progress_indicator{
          margin-top: 0;
          padding: 0.5em;
          font-size: 15px;
        }
      }
    }
  }
  @media (max-height: 430px) and (orientation: landscape) {
    .mm_settings_container{
      padding-top: 0.6em;
      font-size: 18px;
    }
    .searching_progress{
      padding-top: 1em;
    }
  }
}

// .minimized{
//   .searching_game{
//     display: none;
//   }
// }

.game_found_modal{
  width: 100%;
  .players_container {
    align-items: flex-start;
    .setting_box{
      margin-left: 23px;
      text-align: center;
      .setting_box_buttons{
        display: flex;
        justify-content: center;
      }
      &:first-child{
        margin-left: 0;
      }
    }
  }
  .player_statuses{
    width: 100%;
    margin-top: 48px;
    .players_holder{
      padding: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      .player {
        margin-left: 40px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        opacity: 0.5;
        &:first-child{
          margin-left: 0;
        }
        &.active{
          opacity: 1;
        }
        .user_circle{
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background: #CDCDCD;
        }
        .user_name{
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #333333;
        }
      }
    }
  }
  .join_button_container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 46px;
    p{
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #333333;
      span{
        font-weight: 700;
      }
    }
    .join_button{
      width: 192px;
      height: 64px;
    }
    .leave_button{
      width: 112px;
      height: 40px;
    }
  }
}

.modal_title{
  width: 100%;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

.search_game_holder{
  display: flex;
  justify-content: center;
  margin-top: 32px;
  .search_game{
    font-size: 18px;
    line-height: 22px;
    width: 192px;
    height: 64px;
  }
}

.setup_button{
  background: #EEEEEE;
  border: 2px solid #333333;
  box-sizing: border-box;
  border-radius: 32px;
  font-family: FiraGO;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #333333;
  cursor: pointer;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.with_text{
    padding: 0 20px;
  }
  &.with_numbers{
    width: 32px;
  }
  &.active{
    background: #333333;
    border: 2px solid #333333;
    box-sizing: border-box;
    border-radius: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.invite_friends_button{
  @extend %large-primary-main;
}