@import '../../functions';

.loader_outer{
  font-size: 48px;
  width: px-to-em(48, 48);
  height: px-to-em(48, 48);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(37, 40, 43, 0.04);
  .loader_inner{
    width: px-to-em(30, 48);
    height: px-to-em(30, 48);
    position: relative;
    animation: rotate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    .loader_circle{
      width: px-to-em(9, 48);
      height: px-to-em(9, 48);
      border-radius: 50%;
      position: absolute;
      &.red{
        background: #DB4D4D;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      &.green{
        background: #41A846;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &.blue{
        background: #4363D7;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &.yellow{
        background: #F9CD4B;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &.minimized{
    width: 32px;
    height: 32px;
    .loader_inner{
      width: 20px;
      height: 20px;
      .loader_circle{
        width: 6px;
        height: 6px;
      }
    }
  }
  &.size16{
    font-size: 16px;
  }
  &.size32{
    font-size: 32px;
  }
  &.size35 {
    font-size: 35px;
  }
  &.size60{
    font-size: 60px;
  }
  &.transparent{
    background: transparent;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}