@import '../../functions';
@import '../../extends/buttons.scss';
.tooltipContainer {
  // width: 380px;
  // height: 190px;
  background-color: color(White, Solid);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  border-radius: 24px;
  min-width: 314px;
  transition: all 500ms;
  box-sizing: border-box;
  font-size: 14px;
  .triangle {
    width: 0;
    height: 0;
    border-top: none;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid color(White, Solid);
    // border-bottom: transparent;
    position: absolute;
    transition: all 500ms;
    left: 50%;
    transform: translateX(-50%);
    top: -8px;
  }
  .content{
    // width: 100%;
    // height: 100%;
    padding: 35px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .image_container{
      // width: getPercent(135, 380);
      // padding-right: 9%;
      img{
        // width: 100%;
        // max-width: 80px;
      }
      .bigAvatars {
        // width: 83%;
      }
    }
    .descriptionContainer {
      // width: getPercent(245, 380);
      margin-left: 20px;
      max-width: 300px;
      h2 {
        font-weight: 600;
        font-size: px-to-em(18, 14);
        color: color(Black, Solid, 500);
        margin-bottom: px-to-em(8, 18);
        width: 100%;
        text-align: left;
      }
      p {
        font-size: px-to-em(16, 14);
        color: color(Black, Solid, 400);
        width: 100%;
        text-align: left;
      }
    }
  }
  &.top{
    transform: translateY(-100%);
    .triangle{
      transform: rotate(180deg) translateY(2px);
      top: 100%;
    }
  }
  &.triangleleft{
    .triangle{
      left: 25%;
      transform: translateX(-50%);
    }
  }
  &.triangleright{
    .triangle{
      left: 75%;
      transform: translateX(-50%);
    }
  }
  &.hidden {
    display: none;
  }
  @media (min-width: 993px) {
    // &.large{
    //   height: 220px;
    // }  
  }
}


@media (max-width: 992px) {
  @media  (orientation: landscape) {
    .tooltipContainer {
      // width: 340px;
      height: auto;
      .content{
        padding: 14px 13px;
        .image_container{
          width: 20%;
          padding-right: 8px;
          img{
            max-width: 40px;
          }
        }
        .descriptionContainer{
          // width: 80%;
          h2{
            font-size: 16px;
            margin-bottom: 2px;
          }
          p{
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  @media (orientation: portrait) {
    .tooltipContainer {
      width: 344px;
      height: auto;
      .content{
        padding: 24px 13px;
        .image_container{
          // width: 20%;
          // padding-right: 8px;
          // img{
          //   max-width: 40px;
          // }
        }
        .descriptionContainer{
          // width: 80%;
          p{
            font-size: 14px;
          }
        }
      }
      &.large{
        height: 230px;
      }
    }
  }
}
@media (max-width: 359px) {
  @media (orientation: portrait) {
    .tooltipContainer {
      width: 294px;
      min-width: unset;
    }
  }
}

.stepper_container{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  .skip_text{
    font-size: 1em;
    font-weight: 700;
    // text-transform: capitalize;
    color: color(White, Transparent, 400);
    cursor: pointer;
  }
  .action_button_container{
    margin-left: px-to-em(48, 18);
    button{
      @extend %large-tertiary-indicator;
      width: auto;
      span {
        margin-right: 0.2em;
      }
    }
  }
  &.hidden {
    display: none;
  }
  @media (max-width: 992px) {
    font-size: 16px;
    button{
      font-size: 12px!important;
      width: 128px!important;
      height: 40px!important;
    }
  }
  @media (max-width: 768px) {
    @media (orientation:portrait) {
      width: 100%;
      justify-content: space-between;
      padding: 0 24px;
    }
  }
}