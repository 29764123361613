$large-input-font-size: 14;

// %large-regular-default{
//   font-size: px-to-rem($large-input-font-size, 16);
//   width: 100%;
//   .input_content{
//     width: 100%;
//     height: px-to-em(56, $large-input-font-size);
//     line-height: px-to-em(17, $large-input-font-size);
//     position: relative;
//     z-index: 1;
//     &:hover{
//       input{
//         border-color: color(Black, Transparent, 300);
//         box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.06);
//       }
//     }
//     // margin-top: px-to-em(16, 14);
//     input{
//       font-size: inherit;
//       line-height: inherit;
//       width: 100%;
//       height: 100%;
//       border-radius: px-to-em(12, $large-input-font-size);
//       background: color(Black, Solid, 25);
//       border: 1px solid color(Black, Transparent, 100);
//       color: color(Black, Solid, 300);
//       padding-left: px-to-em(16, $large-input-font-size);
//       box-sizing: border-box;
//       box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02);
//       transition: all 500ms;
//       // width: 100%;
//       &.active, &:-internal-autofill-selected{
//         &+.input_text{
//           top: 20%;
//           font-size: px-to-em(12, $large-input-font-size);
//           left: px-to-em(16, 12);
//         }
//       }
//       &:focus{
//         background: color(Primary, Solid, 50);
//         border-color: color(Primary, Solid, 500);
//         box-shadow: 0px 6px 8px rgba(35, 71, 133, 0.06);
//         color: color(Primary, Solid, 500);
//         &+.input_text{
//           top: 20%;
//           font-size: px-to-em(12, $large-input-font-size);
//           left: px-to-em(16, 12);
//           color: color(Primary, Solid, 300);
//         }
//         &+.input_text+.icon{
//           svg {
//             path {
//               fill: color(Black, Transparent, 300);
//               fill-opacity: 1;
//             }
//           }
//         }
//       }
//     }
//     .input_text{
//       font-size: inherit;
//       line-height: inherit;
//       color: color(Black, Solid, 300);
//       position: absolute;
//       top: 50%;
//       left: px-to-em(16, $large-input-font-size);
//       transform: translateY(-50%);
//       transition: all 500ms;
//       pointer-events: none;
//       &.active{
//         top: 20%;
//         font-size: px-to-em(12, $large-input-font-size);
//         left: px-to-em(16, $large-input-font-size);
//       }
//     }
//     .indicator{
//       position: absolute;
//       right: getPercent(20, 272);
//       top: 50%;
//       transform: translateY(-50%);
//       &.success {
//         @extend %indicator-success;
//       }
//       &.fail {
//         @extend %indicator-error;
//       }
//     }
//     .icon{
//       position: absolute;
//       right: getPercent(20, 272);
//       top: 50%;
//       transform: translateY(-50%);
//       transition: all 500ms;
//       cursor: pointer;
//       svg {
//         path {
//           transition: all 500ms;
//         }
//       }
//       &.movedLeft {
//         right: getPercent(60, 272);
//       }
//     }
//   }
//   &.error{
//     .input_content{
//       input {
//         background: color(Negative, Solid, 25);
//         border-color: color(Negative, Solid, 500);
//         box-shadow: 0px 6px 4px rgba(190, 52, 66, 0.06);
//         color: color(Negative, Solid, 500)!important;
//         &:focus{
//           box-shadow: 0px 6px 4px rgba(190, 52, 66, 0.06);
//           color: color(Primary, Solid, 500);
//           &+.input_text{
//             top: 20%;
//             color: color(Negative, Solid, 500);
//           }
//         }
//       }
//       .input_text{
//         color: color(Negative, Solid, 500);
//       }
//     }
//     .error_text {
//       display: inline-block;
//       width: 100%;
//       font-size: px-to-em(12, $large-input-font-size);
//       padding: px-to-em(8, 12);
//       color: color(Negative, Solid, 500);
//       min-height: px-to-em(30, 12);
//     }
//   }
//   &.disabled{
//     opacity: 0.5;
//     cursor: no-drop;
//   }
// }

%large-regular-default{
  // font-size: px-to-rem(16, 16);
  font-size: 16px;
  width: 114.285714%;
  transform: scale(0.875);
  transform-origin: 0 0;
  .input_content{
    width: 100%;
    height: px-to-em(64, 16);
    line-height: px-to-em(19, 16);
    position: relative;
    z-index: 1;
    &:hover{
      input{
        border-color: color(Black, Transparent, 300);
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.06);
      }
    }
    // margin-top: px-to-em(16, 14);
    input{
      font-size: inherit;
      line-height: inherit;
      width: 100%;
      height: 100%;
      border-radius: px-to-em(14, 16);
      background: color(Black, Solid, 25);
      border: 1px solid color(Black, Transparent, 100);
      color: color(Black, Solid, 300);
      padding-left: px-to-em(18, 16);
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02);
      transition: all 500ms;
      // width: 100%;
      &.active, &:-internal-autofill-selected{
        &+.input_text{
          top: 20%;
          font-size: px-to-em(14, 16);
          left: px-to-em(18, 14);
        }
      }
      &:focus{
        background: color(Primary, Solid, 50);
        border-color: color(Primary, Solid, 500);
        box-shadow: 0px 6px 8px rgba(35, 71, 133, 0.06);
        color: color(Primary, Solid, 500);
        &+.input_text{
          top: 20%;
          font-size: px-to-em(14, 16);
          left: px-to-em(16, 14);
          color: color(Primary, Solid, 300);
        }
        &+.input_text+.icon{
          svg {
            path {
              fill: color(Black, Transparent, 300);
              fill-opacity: 1;
            }
          }
        }
      }
    }
    .input_text{
      font-size: inherit;
      line-height: inherit;
      color: color(Black, Solid, 300);
      position: absolute;
      top: 50%;
      left: px-to-em(18, 16);
      transform: translateY(-50%);
      transition: all 500ms;
      pointer-events: none;
      &.active{
        top: 20%;
        font-size: px-to-em(14, 16);
        left: px-to-em(16, 14);
      }
    }
    .indicator{
      position: absolute;
      right: getPercent(20, 272);
      top: 50%;
      transform: translateY(-50%);
      &.success {
        @extend %indicator-success;
      }
      &.fail {
        @extend %indicator-error;
      }
    }
    .icon{
      position: absolute;
      right: getPercent(20, 272);
      top: 50%;
      transform: translateY(-50%);
      transition: all 500ms;
      cursor: pointer;
      svg {
        path {
          transition: all 500ms;
        }
      }
      &.movedLeft {
        right: getPercent(60, 272);
      }
    }
  }
  &.error{
    .input_content{
      input {
        background: color(Negative, Solid, 25);
        border-color: color(Negative, Solid, 500);
        box-shadow: 0px 6px 4px rgba(190, 52, 66, 0.06);
        color: color(Negative, Solid, 500)!important;
        &:focus{
          box-shadow: 0px 6px 4px rgba(190, 52, 66, 0.06);
          color: color(Primary, Solid, 500);
          &+.input_text{
            top: 20%;
            color: color(Negative, Solid, 500);
          }
        }
      }
      .input_text{
        color: color(Negative, Solid, 500);
      }
    }
    .error_text {
      display: inline-block;
      width: 100%;
      font-size: px-to-em(14, 16);
      padding: px-to-em(9, 14);
      color: color(Negative, Solid, 500);
      min-height: px-to-em(34, 12);
    }
  }
  &.disabled{
    opacity: 0.5;
    cursor: no-drop;
  }
}

%date-input{
  font-size: 16px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: px-to-em(10, 16) 1em;
  border: 1px solid #234785;
  box-sizing: border-box;
  border-radius: 12px;
  .date_inputs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    .input_title{
      font-size: px-to-em(12, 16);
      color: color(Black, Solid, 300);
      font-weight: 400;
      width: 100%;
    }
  }
  .input_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
    input {
      border: none;
      background: transparent;
      padding: 0;
      display: inline-block;
      width: 50px;
      &::placeholder{
        width: 100%;
        text-align: left;
        color: rgba(35, 71, 133, 0.16);
      }
      &.small {
        width: 22px;
        text-align: left;
      }
    }
  }
  .icon_container {
    position: absolute;
    right: getPercent(24, 272);
    top: 50%;
    transform: translateY(-50%);
    transition: all 500ms;
    cursor: pointer;
  }
}

%indicator-success {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: px-to-rem($large-input-font-size, 16);
  width: px-to-em(24, $large-input-font-size);
  height: px-to-em(24, $large-input-font-size);
  border-radius: 50%;
  background: color(Positive, Transparent, 100);
  svg {
    width: 60%;
   path {
     fill: color(Positive, Solid, 500);
   } 
  }
}

%indicator-error {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: px-to-rem($large-input-font-size, 16);
  width: px-to-em(24, $large-input-font-size);
  height: px-to-em(24, $large-input-font-size);
  border-radius: 50%;
  background: color(Negative, Transparent, 100);
  svg {
   path {
     fill: color(Negative, Solid, 500);
   } 
  }
}