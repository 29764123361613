@import '../../functions';
@import '../../extends';
.form_group{
  @extend %large-regular-default;
}

.search_input{
  @extend %large_search;
  width: 100%;
}

.select{
  @extend %large-select;
}

.multi_select{
  @extend %large-select;
  width: 272px;
  height: 360px;
  .list{
    width: 100%;
    height: 100%;
    .dropdown_search{
      height: 15%;
      .search_input{
        input {
          height: 40px;
        }
      }
    }
    .list_ul{
      height: 65%!important;
      .list_li{
        padding: 0.5em;
        .item_container{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .check_container{
            margin-right: px-to-em(18, 16);
          }
          .image_container{
            margin-right: px-to-em(13, 16);
          }
          .listItem_title{
            &.selected {
              color: color(Primary, Solid, 500)
            }
          }
        }
      }
    }
    .save_button_container{
      height: 20%;
      background: color(White, Solid);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        @extend %large-primary-main;
        width: 80%;
        height: 40px;
      }
    }
  }
}

.checkbox{
  @extend %checkbox;
  svg {
    path {
      fill: #fff;
    }
  }
}

.date_input{
  @extend %date-input;
}

.custom_dropdown{
  @extend %dropdown;
}