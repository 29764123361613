@import '../../functions';
@import '../../extends';

.notification_container{
  font-size: 14px;
  // padding: px-to-em(16, 14);
  box-sizing: border-box;
  width: 100%;
  border-radius: px-to-em(16, 14);
  padding: px-to-em(16, 14) px-to-em(8, 14);
  position: relative;
  .notification{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .left_side{
      width: 56px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      cursor: pointer;
    }
    .right_side{
      width: 100%;
      .top_side {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .notification_avatar_container{
          margin-right: px-to-em(12, 14);
        }
        .notification_texts{
          .title{
            font-size: 1em;
            font-weight: 600;
            line-height: px-to-em(17, 14);
            color: color(Black, Solid, 400)
          }
          .text{
            font-size: px-to-em(12, 14);
            font-weight: normal;
            line-height: px-to-em(14, 12);
            margin-top: px-to-em(4, 12);
            color: color(Black, Solid, 400);
          }
        }
        .notification_status{
          position: absolute;
          top: px-to-em(10, 14);
          right: px-to-em(16, 14);
          span {
            font-size: px-to-em(10, 14);
            line-height: px-to-em(12, 10);
            &.new{
              color: color(Positive, Solid, 500);
              font-weight: 500;
              padding: px-to-em(2, 10) px-to-em(6, 10);
              background: color(Positive, Transparent, 100);
              border-radius: px-to-em(24, 10);
            }
            &.date{
              color: color(Black, Solid, 300);
              padding-left: px-to-em(6, 10);
            }
          }
        }
      }
      .bottom_side{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1em;
        button {
          // margin-right: px-to-em(8, 14);
          &.accept {
            @extend %small-positive-main;
            width: auto;
            padding: 0 12px;
          }
          &.decline {
            @extend %small-negative-outline;
            width: auto;
            padding: 0 12px;
            margin-right: .5em;
          }
          &:last-child{
            // margin-right: 0;
          }
        }
        p{
          font-size: 1em;
          font-weight: 700;
          text-align: center;
          width: 100%;
          // text-transform: capitalize;
          &.positive{
            color: color(Positive, Solid, 500);
          }
          &.negative{
            color: color(Negative, Solid, 500);
          }
        }
        .go_to{
          @extend %small-positive-main;
          width: auto;
        }
        .canceled {
          // @extend %small-negative-main;
          width: auto;
        }
        @media (max-width: 400px) {
          display: flex;
          // flex-direction: column;
          // align-items: flex-start;
          button {
            &:first-child {
              // margin-bottom: 8px;
            }
          }
        }
      }  
    }
  }

  &.notSeen{
    background: color(Black, Transparent, 25);
    cursor: pointer;
  }

}