@import '../../functions';
@import '../../extends';

.avatar{
  $background-blue: color(Blue, Solid, 50);
  $border-blue: color(Blue, Solid);
  $background-red: color(Red, Solid, 50);
  $border-red: color(Red, Solid);
  $background-green: color(Green, Solid, 50);
  $border-green: color(Green, Solid);
  $background-yellow: color(Yellow, Solid, 50);
  $border-yellow: color(Yellow, Solid);
  display: inline-block;
  width: auto;
  position: relative;
  text-align: center;
  .image_holder{
    display: inline-block;
    background: linear-gradient(247.2deg, #FFFFFF 5.25%, #E4E6F2 77.64%);
    border: 2px solid #F1F4F8;
    box-shadow: 0px 4px 8px rgba(63, 81, 113, 0.16);
    border-radius: 50%;
    position: relative;
    box-sizing: border-box;
    overflow: visible;    
    &.custom_image{
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 50%;
        position: unset!important;
      }
    }

    &.blue {
      background: $background-blue;
      border: 4px solid $border-blue;
      &.glow{
        animation: glowBlue 1s ease-in-out infinite alternate;
      }
      &.has_shadow{
        box-shadow: 0px 0px 16px 4px rgba(20, 60, 205, 0.8);
      }
    }
    &.green{
      background: $background-green;
      border: 4px solid $border-green;
      &.glow{
        animation: glowGreen 1s ease-in-out infinite alternate;
      }
      &.has_shadow{
        box-shadow: 0px 0px 16px 4px rgba(17, 146, 24, 0.8);
      }

    }
    &.yellow{
      background: $background-yellow;
      border: 4px solid $border-yellow;
      &.glow{
        animation: glowYellow 1s ease-in-out infinite alternate;
      }
      &.has_shadow{
        box-shadow: 0px 0px 16px 4px rgba(248, 193, 30, 0.8);
      }

    }
    &.red {
      background: $background-red;
      border: 4px solid $border-red;
      &.glow{
        animation: glowRed 1s ease-in-out infinite alternate;
      }
      &.has_shadow{
        box-shadow: 0px 0px 16px 4px rgba(210, 32, 32, 0.8);
      }

    }
    &.large{
      width: 128px;
      height: 128px;
    }
    .inner_circle {
      background-color: color(Black,Transparent, 300);
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: center;
      display: block;
      position: absolute;
      width: 98%;
      border-radius: 50%;
      height: 98%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      &.bad_signal{
        background-image: url('/badsignal.svg');
      }
      &.offline{
        background-image: url('/offline.svg');
      }
    }
    &.medium{
      width: 64px;
      height: 64px;
    }
    &.medium-plus{
      width: 80px;
      height: 80px;
    }
    &.size96{
      width: 96px;
      height: 96px;
    }
    &.size400 {
      width: 400px;
      height: 400px;
      border-width: 12px;
      // img {
      //   left: 50%;
      //   transform: translate(-50%, 50%);
      //   width: 90%;
      //   bottom: 55%;
      // }
    }
    &.size48{
      width: 48px;
      height: 48px;
    }
    &.rematch-size {
      width: 40px;
      height: 40px;
    }
    &.small{
      width: 32px;
      height: 32px; 
    }
    &.extraSmall{
      width: 24px;
      height: 24px;
    }
    &.active{
      border-top-width: 4px;
      border-right-width: 4px;
      border-bottom-width: 4px;
      border-left-width: 4px;

    }
    // &::after{
    //   position: absolute;
    //   transform: rotate(180deg);
    //   bottom: 0;
    //   left: 0;
    //   content: '';
    //   display: block;
    //   width: 100%;
    //   height: 50%;
    //   border-top-left-radius: 64px;
    //   border-top-right-radius: 64px;
    //   border: 1px solid color(Blue, Solid, 50);
    //   border-bottom: 0;
    // }
    img {
      display: block;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 2px;
      // border-top-left-radius: 50%;
      // border-top-right-radius: 50%;
      // border-bottom-right-radius: 50%;
      // border-bottom-left-radius: 50%;
    }
  }
  .country_image_holder{
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 25%);
    img {
      display: block;
    }
    &.small{
      img{
        width: 15px;
      }
    }
    &.size96{
      img {
        width: 30px;
      }
    }
    &.medium-plus{
      img {
        width: 25px;
      }
    }
  }
  .edit_icon{
    // @extend %small-tertiary-round;
    position: absolute;
    top: 0;
    right: 0;
    &.disabled{
      opacity: 0.5;
      cursor: no-drop;
    }
  }
  .loading_status{
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(25%);
    width: 16px;
    height: 16px;
    .size16{
      width: 16px;
      height: 16px;
    }
    .joined{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: color(Green, Transparent, 100);
      svg{
        width: 60%;
      }
    }
  }
  .choose_avatar_overlay{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: color(Positive, Transparent, 400);
    border: 4px solid color(Positive, Solid, 600);
    box-sizing: border-box;
    transition: all 500ms;
    svg{
      width: 30%;
      path{
        fill: color(White, Solid);
      }
    }
    &.size96{
      width: 96px;
      height: 96px;
    }
    &.medium{
      width: 64px;
      height: 64px;
    }
  }
  &.showHover{
    @media (hover: hover) {
      // &:hover{
      //   .choose_avatar_overlay{
      //     opacity: 1;
      //   }
      // }
    }
    @media (pointer: fine) {
      &:hover{
        .choose_avatar_overlay{
          opacity: 1;
        }
      }
    }
  }
  &.active{
    .choose_avatar_overlay{
      opacity: 1;
    }
  }
  .online_dot{
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: color(Positive, Solid, 500);
  }
  .avatar_username{
    display: block;
    width: 100%;
    text-align: center;
    color: color(Black, Transparent, 400);
    font-size: px-to-em(10, 16);
    font-weight: 500;
    margin-top: 0.25rem;
  }
}

.gif_container{
  z-index: -1;
  pointer-events: none;  
  filter: blur(10px);
}

@keyframes glowBlue {
  from {
    box-shadow: 0px 0px 32px rgba(20, 60, 205, 0.32);
  }
  
  to {
    box-shadow: 0px 0px 32px 4px rgba(20, 60, 205, 0.64);
  }
}
@keyframes glowRed {
  from {
    box-shadow: 0px 0px 32px 4px rgba(210, 32, 32, 0.32);
  }
  
  to {
    box-shadow: 0px 0px 32px 4px rgba(210, 32, 32, 0.64);
  }
}
@keyframes glowYellow {
  from {
    box-shadow: 0px 0px 32px 4px rgba(248, 193, 30, 0.32);
  }
  
  to {
    box-shadow: 0px 0px 32px 4px rgba(248, 193, 30, 0.64);
  }
}
@keyframes glowGreen {
  from {
    box-shadow: 0px 0px 32px 4px rgba(17, 146, 24, 0.32);
  }
  
  to {
    box-shadow: 0px 0px 32px 4px rgba(17, 146, 24, 0.64);
  }
}

.inGameAcitivityStatus{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // background: red;
  border-radius: 50%;
  background: color(Black, Transparent, 300);
  display: flex;
  justify-content: center;
  align-items: center;
}