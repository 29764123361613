$large_select_font_size: 14;

%large-select{
  font-size: px-to-rem($large_select_font_size, 16);
  width: 100%;
  // height: px-to-em(56, $large_select_font_size);
  // border-radius: px-to-em(12, $large_select_font_size);
  // border: 1px solid color(Black, Transparent, 100);
  // box-sizing: border-box;
  // box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.08);
  // background: color(White, Solid);
  position: relative;
  cursor: pointer;
  .select_input{
    width: 100%;
    position: relative;
    height: px-to-em(56, $large_select_font_size);
    border-radius: px-to-em(12, $large_select_font_size);
    border: 1px solid color(Black, Transparent, 100);
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.08);
    background: color(White, Solid);
    position: relative;
    cursor: pointer;
    transition: all 500ms;
    .label_text{
      font-size: 1em;
      color: color(Black, Solid, 300);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: px-to-em(16, $large_select_font_size);    
      transition: all 500ms;
    }
    .chosen_value{
      display: inline-block;
      font-size: px-to-em(16, $large_select_font_size);
      line-height: px-to-em(19, 16);
      font-weight: 500;
      color: color(Black, Solid, 400);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1em;
      opacity: 0;
      transition: all 500ms;
      width: px-to-em(200, 16);
      height: 1.5em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform: translateY(-#{px-to-em(5, 16)});
    }
    .icon{
      position: absolute;
      width: px-to-em(32, $large_select_font_size);
      height: px-to-em(32, $large_select_font_size);
      border-radius: 50%;
      background: color(Black, Transparent, 25);
      display: flex;
      justify-content: center;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
      right: px-to-em(16, $large_select_font_size);
    }
    &.filled {
      .label_text{
        font-size: px-to-em(12, $large_select_font_size);
        left: px-to-em(16, 12);
        top: 20%;  
      }
      .chosen_value{
        opacity: 1;
      }
    }
    &.active{
      border-color: color(Primary, Solid, 500);
      .label_text{
        color: color(Primary, Solid, 500)
      }
      .chosen_value{
        color: color(Primary, Solid, 500);
      }
    }
  }
  .list{
    font-size: 1em;
    width: 100%;
    height: px-to-em(270, $large_select_font_size);
    padding: px-to-em(8, $large_select_font_size) px-to-em(8, $large_select_font_size) 0;
    border-radius: px-to-em($large_select_font_size);
    background: color(White, Solid, 500);
    box-shadow: 0px 8px 16px rgba(51, 51, 51, 0.2);
    overflow: hidden;
    box-sizing: border-box;
    margin-top: px-to-em(10, $large_select_font_size);
    position: relative;
    z-index: 1;
    .dropdown_search{
      display: none;
      height: 25%;
      width: 100%;
      // display: flex;
      justify-content: center;
      align-items: center;
      // input {
      //   width: 114.285714%;
      //   transform: scale(0.85);
      //   transform-origin: 0 0 ;
      // }
    }
    // overflow-y: auto;
    .list_ul{
      // height: 80%;
      height: 100%;
      padding: px-to-em(8, $large_select_font_size) 0 0 0;
      overflow-y: auto;
      .list_li{
        width: 100%;
        font-size: px-to-em(16, $large_select_font_size);
        border-radius: px-to-em(12, 16);
        // margin-top: px-to-em(5, 12);
        color: color(Black, Solid, 400);
        transition: all 500ms;
        padding: 1em;
        &:hover{
          background: color(Primary, Transparent, 25);
          color: color(Primary, Solid, 500);
        }
        &:first-child{
          margin-top: 0;
        }
      }
    }
    &.with_search{
      padding: 0 px-to-em(8, $large_select_font_size);
      .dropdown_search{
        display: flex;
      }
      .list_ul{
        height: 75%;
      }
    }
  }
  &.disabled {
    opacity: 0.8;
    cursor: no-drop;
    .select_input{
      cursor: no-drop;
    }
  }
  &.absolute{
    .list{
      position: absolute;
    }
  }
}

%dropdown {
  font-size: px-to-rem($large_select_font_size, 16);
  width: 100%;
  // height: px-to-em(56, $large_select_font_size);
  // border-radius: px-to-em(12, $large_select_font_size);
  // border: 1px solid color(Black, Transparent, 100);
  // box-sizing: border-box;
  // box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.08);
  // background: color(White, Solid);
  position: relative;
  cursor: pointer;
  .dorpdown_value{
    width: 100%;
    position: relative;
    height: px-to-em(56, $large_select_font_size);
    border-radius: px-to-em(12, $large_select_font_size);
    border: 1px solid color(Black, Transparent, 100);
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.08);
    background: color(White, Solid);
    position: relative;
    cursor: pointer;
    transition: all 500ms;
    .dorpdown_label{
      font-size: 1em;
      color: color(Black, Solid, 300);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: px-to-em(16, $large_select_font_size);    
      transition: all 500ms;
      font-weight: normal;
    }
    .dropdown_value{
      display: inline-block;
      font-size: px-to-em(16, $large_select_font_size);
      line-height: px-to-em(19, 16);
      font-weight: 500;
      color: color(Black, Solid, 400);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1em;
      opacity: 0;
      transition: all 500ms;
      width: px-to-em(200, 16);
      height: 1.5em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform: translateY(-#{px-to-em(5, 16)});
    }
    .icon_container{
      position: absolute;
      width: px-to-em(32, $large_select_font_size);
      height: px-to-em(32, $large_select_font_size);
      border-radius: 50%;
      background: color(Black, Transparent, 25);
      display: flex;
      justify-content: center;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
      right: px-to-em(16, $large_select_font_size);
    }
    &.filled {
      .dorpdown_label{
        font-size: px-to-em(12, $large_select_font_size);
        left: px-to-em(16, 12);
        top: 20%;  
      }
      .dropdown_value{
        opacity: 1;
      }
    }
    &.active{
      border-color: color(Primary, Solid, 500);
      .label_text{
        color: color(Primary, Solid, 500)
      }
      .chosen_value{
        color: color(Primary, Solid, 500);
      }
    }
  }
  .dropdown_list{
    font-size: 1em;
    width: 100%;
    height: px-to-em(270, $large_select_font_size);
    padding: px-to-em(8, $large_select_font_size) px-to-em(8, $large_select_font_size) 0;
    border-radius: px-to-em($large_select_font_size);
    background: color(White, Solid, 500);
    box-shadow: 0px 8px 16px rgba(51, 51, 51, 0.2);
    overflow: hidden;
    box-sizing: border-box;
    // margin-top: px-to-em(10, $large_select_font_size);
    position: relative;
    top: 5px;
    // overflow-y: auto;
    .dropdown_list_ul{
      // height: 80%;
      height: 100%;
      // padding: px-to-em(8, $large_select_font_size) 0 0 0;
      overflow-y: auto;
      list-style-type: none;
      padding: 0;
      .list_item{
        width: 100%;
        .dropdown_item_container{
          width: 100%;
          font-size: px-to-em(14, $large_select_font_size);
          border-radius: px-to-em(12, 14);
          // margin-top: px-to-em(5, 12);
          color: color(Black, Solid, 400);
          transition: all 500ms;
          padding: 1em 0.5em;
          .list_item_text{
            font-weight: normal;
          }
          &:hover{
            background: color(Primary, Transparent, 25);
            color: color(Primary, Solid, 500);
          }
          &.active {
            background: color(Primary, Transparent, 25);
            color: color(Primary, Solid, 500);
          }
        }
        &:first-child{
          margin-top: 0;
        }
      }
      &.grid {
        display: flex;
        flex-wrap: wrap;
        .list_item{
          width: calc(100% / 3);
          .dropdown_item_container{
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
  &.disabled{
    cursor: no-drop;
    opacity: 0.8;
  }
  &.absolute{
    .dropdown_list{
      position: absolute;
      top: px-to-em(61, $large_select_font_size);
    }
  }
  &.small{
    width: 100%;
    height: 100%;
    .dorpdown_value{
      height: 100%;
      .dropdown_value{
        font-size: 13px;
        height: auto;
        width: auto;
        transform: translateY(-50%);
      }
      .icon_container{
        width: 24px;
        height: 24px;
      }
    }
    .dropdown_list{
      font-size: 12px;
    }
  }
}