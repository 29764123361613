$large_search_font: 14;

%large_search {
  font-size: 16px;
  position: relative;
  input {
    font-size: 16px;
    width: 114.285714%;
    transform: scale(0.875);
    transform-origin: 0 0;
    height: px-to-em(48, 16);
    border-radius: px-to-em(32, 16);
    background: color(Black, Solid, 25);
    border: 1px solid color(Black, Transparent, 100);
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02);
    padding-left: px-to-em(24, 16);
    color: color(Black, Solid, 300);
    font-weight: 500;
    &::placeholder{
      color: color(Black, Solid, 300);
      font-size: inherit;
      // text-transform: capitalize;
      font-weight: 400;
    }
  }
  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: px-to-em(24, 16);
    .indicator{
      @extend %indicator;
    }
  }
  &:hover{
    input {
      border-color: color(Black, Transparent, 300);
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.06);
      &::placeholder{
        color: color(Black, Solid, 300);
        font-size: inherit;
        // text-transform: capitalize;
      }  
    }
  }
  &.active {
    input {
      background: color(Black, Solid, 50);
      border-color: color(Primary, Solid, 500);
      box-shadow: 0px 6px 8px rgba(35, 71, 133, 0.06);
      color: color(Primary, Solid, 500);
    }
  }
  &.disabled {
    opacity: 0.5;
  }
}

%medium_search {
  @extend %large_search;
  height: px-to-em(40, $large_search_font);  
}

%indicator{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: px-to-rem($large_search_font, 16);
  width: px-to-em(24, $large_search_font);
  height: px-to-em(24, $large_search_font);
  border-radius: 50%;
  background: color(Black, Transparent, 50);
  cursor: pointer;
  svg {
   path {
     stroke: color(Black, Transparent, 300);
   } 
  }
}
