%chip{
  font-size: 16px;
  width: auto;
  min-width: px-to-em(72, 16);
  height: px-to-em(40, 16);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: px-to-em(24, 16);
  color: color(Primary, Solid, 500);
  background: color(Primary, Transparent, 25);
  transition: all 500ms;
  cursor: pointer;
  &:hover{
    background: color(Primary, Transparent, 50);
  }
  &.active{
    background: color(Primary, Solid, 500);
    color: color(White, Solid);
  }
  &.disabled{
    opacity: 0.8;
    cursor: no-drop;
  }
}

%chip-indicator {
  font-size: px-to-rem(14, 16);
  width: px-to-em(128, 14);
  height: px-to-em(40, 14);
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: color(Primary, Solid, 25);
  border-radius: px-to-em(40, 14);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms;
  border: 1px solid color(Primary, Transparent, 100);
  padding: px-to-em(8, 14) px-to-em(16, 14);
  .text{
    font-size: inherit;
    font-weight: bold;
    // // line-Height: px-to-em(22, $large_button_font);
    color: color(Primary, Solid, 500);
    // text-transform: uppercase;
    transition: all 500ms;
  }
  .indicator{
    font-size: px-to-em(12, 14);
    // padding: px-to-em(7, 14) px-to-em(14, 14);
    width: px-to-em(32, 12);
    height: px-to-em(24, 12);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: px-to-em(24, 12);
    background: color(Primary, Transparent, 50);
    transition: all 500ms;
    margin-left: px-to-em(8, 12);
    span {
      font-size: inherit;
      color: color(Primary, Solid, 500);
      transition: all 500ms;
      font-weight: bold;
    }
  }
  &:hover{
    background: color(Primary, Solid, 600);
    border-color: transparent;
    .text{
      color: color(White, Solid)
    }
    .indicator{
      background: color(White, Transparent, 50);
      span {
        color: color(White, Solid);
      }
    }
  }
  &.active{
    background: color(Primary, Solid, 500);
    .text{
      color: color(White, Solid)
    }
    .indicator{
      background: color(White, Transparent, 50);
      span {
        color: color(White, Solid);
      }
    }
  }
  &:disabled{
    border-color: transparent;
    background: color(Primary, Transparent, 100);
    box-shadow: none;
    cursor: no-drop;
    justify-content: center;
    &:hover{
      background: color(Primary, Transparent, 100);
      border-color: transparent;
      .text{
        color: color(Primary, Solid, 500);
      }
      .indicator{
        background: color(Primary, Transparent, 50);
        span {
          color: color(Primary, Solid, 500);
        }
      }  
    }
  }
}