@import '../../functions';

.backdrop{
  position: fixed;
  width: 200vw;
  height: 100vh;
  // height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: -100vw;
  // background: color(Black, Transparent, 400);
  background: rgba(37, 40, 43, 0.64);
  z-index: 900;
  // opacity: 0;
  transition: all 500ms;
  &.active{
    opacity: 1;
  }
}