@import '../../functions';
@import '../../extends';

.modal_container{
  background: #F6F8FA;
  position: fixed;
  left: 0;
  top: 0;
  // transform: translate(50%, 50%);
  font-size: 16px;
  width: px-to-em(800, 16);
  height: px-to-em(614, 16);
  border-radius: 2em;
  border: 4px solid color(Black, Transparent, 50);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  z-index: 9999;
  --bottom-height: 104px;
  // --image-size: 115px;
  --mode-button-size: 450px;
  // transition: all 500ms;
  .modal_head{
    transition: all 500ms;
    .modal_details_container{
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -37%);
    }
    .modal_image_container{
      width: 80px;
      height: 80px;
      border: 6px solid transparent;
      border-radius: 50%;
      margin: 0 auto;
      &.classic{
        background: #E1E9F9;
        border-color: color(Primary, Solid, 500);
        filter: drop-shadow(-1px 4px 16px rgba(35, 71, 133, 0.32));
      }
      &.private{
        background: #D9EDDA;
        border-color: color(Green, Solid, 500);
        filter: drop-shadow(-1px 4px 16px rgba(17, 146, 24, 0.32));
      }
      &.any{
        border-color: #E4923C;
        filter: drop-shadow(-1px 4px 16px rgba(228, 146, 60, 0.32));
      }
      .modal_image{
        width: 100%;
        height: 100%;
      }
    }
    .modal_title_container{
      width: 100%;
      margin-top: px-to-em(8, 16);
      .modal_title{
        text-align: center;
        font-size: px-to-em(24, 16);
        color: color(Black, Solid, 400);
        font-weight: 700;
      }
    }
    .modal_close_button_container{
      position: absolute;
      right: 24px;
      top: 24px;
    }
    @media (max-width: 1023px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .modal_details_container{
        transform: unset;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        left: 0;
        .modal_title_container{
          width: auto;
          .modal_title{
            display: inline-block;
            width: auto;
            font-size: px-to-em(32, 16);
            padding-left: 1em;
            margin: 0;
          }
        }
        .modal_image_container{
          margin: 0;
        }
      }
      .modal_close_button_container{
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
      }
      @media (orientation: landscape) {
        padding: px-to-em(8, 16) 1em;
        .modal_details_container{
          justify-content: center;
        }
        .modal_image_container{
          width: var(--image-size);
          height: var(--image-size);
        }
      }
      @media (orientation: portrait) {
        padding: 12px getPercent(12, 360);
        .modal_details_container{
          justify-content: flex-start;
        }
        .modal_image_container{
          width: var(--image-size);
          height: var(--image-size);
        }
        .modal_close_button_container{
          button{
            // width: calc(#{var(--image-size)} / 2);
            // height: calc(#{var(--image-size)} / 2);
            width: 32px;
            height: 32px;
            min-width: 32px;
            min-height: 32px;
            img {
              width: 100%;
              max-width: 22px;
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      .modal_details_container{
        .modal_title_container{
          .modal_title{
            font-size: 24px;
          }
        }
      }
      @media (orientation: landscape){

      }
      @media (orientation: portrait) {
        
      }
    }
  }
  .upper_part{
    background: white;
    width: 100%;
    height: calc(100% - var(--bottom-height));
    border-radius: 2em;
    transition: all 500ms;
    .dynamic_part{
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-top: 70px;
      transition: all 500ms;
      // .dynamic_body{
      //   height: calc(100% - px-to-em(35.5, 16));
      //   width: 100%;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      // }
      .search_progress_container{
        padding-top: px-to-em(40, 16);
        display: flex;
        width: 100%;
        height: 100%;
        // background: red;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: all 500ms;
        .search_progress{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          flex-wrap: wrap;
          transition: all 500ms;
          .game_information{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .single_info_container{
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              flex-wrap: wrap;
              font-size: 16px;
              margin-right: px-to-em(24, 16);
              .single_info_title{
                width: 100%;
                text-align: center;
                font-size: 1em;
                color: color(Black, Solid, 400);
                width: 100%;
                text-align: center;
              }
              .single_info_details{
                font-size: 1em;
                width: px-to-em(80, 16);
                height: px-to-em(48, 16);
                border-radius: px-to-em(40, 16);
                border: 1px solid color(Black, Transparent, 50);
                background: color(White, Solid);
                font-weight: 700;
                color: color(Black, Solid, 400);
                display: flex;
                justify-content: center;
                align-items: center;
                // text-transform: capitalize;
                margin-top: px-to-em(8, 16);
              }
              .single_info_list{
                width: 100%;
                margin-top: px-to-em(8, 16);
                .list_items_ul{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  list-style-type: none;
                  padding: 0;
                  .list_item{
                    margin-right: px-to-em(8, 16);
                    .list_item_container{
                      width: px-to-em(48, 16);
                      height: px-to-em(48, 16);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 50%;
                      border: 1px solid color(Black, Transparent, 50);
                      background: color(White, Solid);
                      img {
                        width: 70%;
                      }
                    }
                    &:last-child{
                      margin-right: 0;
                    }
                  }
                }
              }
              &:last-child{
                margin-right: 0;
              }
            }
          }
        }
        .search_progress_information_container{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          margin-top: px-to-em(76, 16);
          transition: all 500ms;
          .search_progress_information{
            display: flex;
            justify-content: center;
            align-items: flex-start;
            transition: all 500ms;
            .serch_dog_image_container{
              width: px-to-em(104, 16);
              transition: all 500ms;
              .search_dog_image{
                width: 100%;
                transition: all 500ms;
              }
            }
            .search_progress_text_container{
              margin-left: px-to-em(12, 16);
              width: px-to-em(250, 16);
              height: px-to-em(80, 16);
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: nowrap;
              padding: 1em;
              box-sizing: border-box;
              border-radius: px-to-em(20, 16) px-to-em(20, 16) px-to-em(20, 16) 0;
              border: 2px solid black;
              margin-top: px-to-em(15, 16);
              transition: all 500ms;
              .search_progress_graphic{
                width: px-to-em(48, 16);
                height: px-to-em(48, 16);
                margin-right: 1em;
                transition: all 500ms;
                .game_found{
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: color(Green, Transparent, 50);
                  &.could_not_start{
                    background: color(Warning, Transparent, 100);
                  }
                }
              }
              .search_progress_text{
                width: calc(100% - #{px-to-em(64, 16)});
                .text_top{
                  width: 100%;
                  text-align: left;
                  font-size: 1em;
                  color: color(Black, Solid, 400);
                  font-weight: 500;
                }
                .bottom_text{
                  width: 100%;
                  text-align: left;
                  font-size: px-to-em(14, 16);
                  margin-top: px-to-em(4, 14);
                  color: color(Black, Solid, 400);
                  font-weight: 300;
                }
              }
              &.searchingGame {
                background: color(Warning, Solid, 100);
                border-color: color(Warning, Transparent, 200);
              }
              &.gameFound {
                background: color(Positive, Solid, 100);
                border-color: color(Positive, Transparent, 200);
              }
              &.gameCouldNotStart, &.ongoingGame{
                background: color(Warning, Solid, 100);
                border-color: color(Warning, Transparent, 200);
                width: unset;
              }
            }
          }
        }
      }
    }
    @media (max-width: 1023px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border-radius: 0;
      .dynamic_part{
        padding-top: 0;
        .dynamic_title{
          padding-top: 0;
        }
        .dynamic_body{
          
        }
      }
      @media (orientation: portrait) {
        .dynamic_part{
          height: calc(100% - #{var(--image-size)} - 24px);
          .dynamic_title{
            font-size: 24px;
          }
          .dynamic_body{
            height: calc(100% - 28px);
            // overflow-y: auto;
          }
        }
      }
      @media (orientation: landscape) {
        .dynamic_part{
          height: calc(100% - #{var(--image-size)} - 16px);
          .dynamic_body{
            height: calc(100% - 19px);
          }
        }
      }
    }
    @media (max-width: 992px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .dynamic_body{
            // overflow-y: auto;
          }
        }
      }
      @media (orientation: landscape) {
        .dynamic_part{
          height: calc(100% - #{var(--image-size)} - 16px);
          .dynamic_title{
            font-size: 16px;
          }
          .search_progress_container{
            padding-top: 20px;
            align-items: center;
            .search_progress{
              .search_progress_information_container{
                margin-top: 24px;
                align-items: center;
                .search_progress_information{
                  .serch_dog_image_container{
                    margin: auto;
                    margin-bottom: 0px;
                    width: 40px;
                  }
                  .searching_progress_info_container{
                    display: flex;
                  .search_progress_text_container{
                    padding: 14px 16px;
                    margin-top: 0;
                    margin-left: 10px;
                    margin-right: 10px;
                    width: 200px;
                    height: 60px;
                    .search_progress_graphic{
                      width: 32px;
                      height: 32px;
                      margin-right: 8px;
                      &>div{
                        font-size: 32px!important;
                      }
                    }
                    .search_progress_text{
                      .text_top{
                        font-size: 12px;
                      }
                      .bottom_text{
                      font-size: 10px;
                      }
                    }
                  } 
                }
                } 
              }
            }
          }
        }
        .searching_progress_players{
          margin-top: 0px;
        }
      }
    }
    @media (max-width: 768px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .search_progress_container{
            .search_progress{
              .game_information{
                flex-wrap: wrap;
                .single_info_container{
                  &:last-child{
                    width: 100%;
                    margin-top: 20px;
                  }
                  &:nth-last-child(2){
                    margin-right: 0;
                  }
                }
              }
              .search_progress_information_container{
                margin-top: 10%;
                align-items: center;
                .search_progress_information{
                  .serch_dog_image_container{
                    width: 40px;
                  }
                  .search_progress_text_container{
                    padding: 14px 16px;
                    margin-top: 0;
                    width: 192px;
                    height: 60px;
                    .search_progress_graphic{
                      width: 32px;
                      height: 32px;
                      margin-right: 8px;
                      &>div{
                        font-size: 32px!important;
                      }
                    }
                    .search_progress_text{
                      .text_top{
                        font-size: 12px;
                      }
                      .bottom_text{
                        font-size: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      @media (orientation: landscape) {
        .dynamic_part{
          .search_progress_container{
            padding-top: 20px;
            align-items: center;

            .search_progress{
              // background: red;
              .search_progress_information_container{
                margin-top: 24px;
                align-items: center;
                .search_progress_information{
                  .serch_dog_image_container{
                    width: 40px;
                  }
                  .search_progress_text_container{
                    padding: 14px 16px;
                    margin-top: 0;
                    width: 192px;
                    height: 60px;
                    .search_progress_graphic{
                      width: 32px;
                      height: 32px;
                      margin-right: 8px;
                      &>div{
                        font-size: 32px!important;
                      }
                    }
                    .search_progress_text{
                      .text_top{
                        font-size: 12px;
                      }
                      .bottom_text{
                        font-size: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 739px) {
      @media (orientation: landscape) {
        .dynamic_part{
          .search_progress_container{
            padding-top: 0;
            .search_progress{
              // background: red;
              .search_progress_information_container{
                margin-top: 15px;
                align-items: center;                
              }
            }
          }
        }
      }
    }
    @media (max-width: 580px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .dynamic_title{
            font-size: 16px;
          }
          .dynamic_body{
            // overflow-y: auto;
          }
        }
      }
    }
    @media (max-width: 500px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .dynamic_body{
            // overflow-y: auto;
          }
        }
      }
    }
    @media (max-width: 400px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .dynamic_body{
            // overflow-y: auto;
          }
        }
      }
    }
    @media (max-width: 359px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .dynamic_body{
            // overflow-y: auto;
          }
        }
      }
    }
  }
  .bottom_part{
    height: var(--bottom-height);
    width: 100%;
    padding: 0 px-to-em(32, 16);
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
    transition: all 500ms;
    .left_side{
      width: px-to-em(160, 16);
      // background: red;
      height: 30px;
      display: none;
    }
    .center{
      width: 100%;
      display: flex;
      justify-content: center;
      .reject_button{
        @extend %large-negative-outline;
      }
      .abort_button {
        @extend %medium-negative-indicator;
      }
      .accept_button{
        @extend %large-primary-main;
        width: 178px!important;
        &.with_indicator{
          @extend %large-primary-indicator;
          width: auto!important;
          padding: 0 24px;
        }
      }
      .buttons_container{
        width: 100%;
        &.counld_not_start{
          display: flex;
          justify-content: center;
          align-items: center;
          .cancel_button{
            @extend %large-primary-outline;
            &:last-child {
              margin-left: 16px;
            }
          }
          .accept_button{
            &:last-child {
              margin-left: 16px;
            }
          }
        }
      }
    }

    .right_side{
      width: px-to-em(160, 16);
      display: none;
      // background: green;
    }
    @media (max-width: 767px) {
      @media (orientation: portrait) {
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        .left_side{
          display: none;
        }
        .center{
          width: 100%;
        }
        .right_side{
          width: 90%;
          max-width: 360px;
        }
      }
      @media (orientation: landscape) {
        .center{
          .setpper_container{

          }
        }
      }
    }
    @media (max-width: 739px){
      padding: 0 16px;
      justify-content: center;
      @media (orientation: landscape){
        .left_side{
          display: none;
        }
        .center{
          width: auto;
        }
      }
    }
    @media (max-width: 359px) {
      @media (orientation: portrait) {
        .center{
          width: 100%;
        }
      }
    }
  }
  .found_back{
    position: absolute;
    right: 17px;
    top: 14px;
    opacity: 0;
    transition: all 500ms;
    svg{}
  }
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
    // transform: translate(0,0);
    border-radius: 0;
    // bottom: 0;
    // right: 0;
    border: none;
    --bottom-height: 110px;
    @media (orientation: landscape) {
      --image-size: 64px;
    }
    @media (orientation: landscape) {
      --bottom-height: 92px
    }
  }
  @media (max-width: 767px) {
    --image-size: 48px;
    --bottom-height: 92px;
    @media (orientation: portrait) {
      .bottom_part{
        &.extended{
          --bottom-height: 160px;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .bottom_part{
      .center {
        .buttons_container{
          flex-wrap: wrap;
          button {
            width: 100%!important;
            margin: 0!important;
            max-width: 312px;
            &:last-child{
              margin-top: 8px!important;
            }
          }
        }
        .reject_button{
          width: 100%!important;
          margin: 0!important;
          max-width: 312px;
        }
        .accept_button{
          &.with_indicator{
            width: 100%!important;
            margin: 0!important;
            max-width: 312px;

          }
        }
      }
    }
  }
  &.minimized{
    width: 312px!important;
    height: 152px!important;
    // transform: translate(0, 0)!important;
    // bottom: 24px!important;
    // right: 24px!important;
    padding: 0!important;
    overflow: hidden!important;
    border: 4px solid color(Black, Transparent, 50)!important;
    border-radius: 2em!important;
    cursor: move;
    transition: unset;
    // height: 152px!important;
    // --bottom-height: 10px;

    .upper_part{
      width: 100%!important;
      height: 100%!important;
      z-index: unset!important;
      .modal_head{
        opacity: 1;
      }
      .dynamic_part{
        padding-top: 0!important;
        height: 100%!important;
        .search_progress_container{
          padding: 0!important;
          .search_progress{
            height: 100%!important;
            .search_progress_information_container{
              margin-top: 0!important;
              .search_progress_information{
                // width: 100%!important;
                height: auto!important;
                align-items: center!important;
                .serch_dog_image_container{
                  width: px-to-em(40, 16)!important;
                }
                .search_progress_text_container{
                  margin: 0!important;
                  height: auto!important;
                  background: transparent!important;
                  border: 0!important;
                  padding: 0 0 0 9px!important;
                  width: 145px;
                  .search_progress_graphic{
                    width: 32px!important;
                    height: 32px!important;
                    margin-right: 8px!important;
                    &>div{
                      font-size: 32px
                    }
                  }
                  .search_progress_text{
                    width: calc(100% - 40px);
                    .text_top{
                      font-size: 12px;
                    }
                    .bottom_text{
                      font-size: 10px;
                    }
                  }
                  &.gameCouldNotStart, &.ongoingGame{
                    width: 200px;
                  }
                }
                .searching_progress_players{
                  display: none!important;
                }
              }
            }
          }
        }
      }
    }
    &.searchingGame{
      border-color: color(Warning, Transparent, 200)!important;
      background: color(Warning, Solid, 100)!important;
    }
    &.gameFound{
      border-color: color(Positive, Transparent, 200)!important;
      background: color(Positive, Solid, 100)!important;
    }
    &.gameCouldNotStart, &.ongoingGame{
      background: color(Warning, Solid, 100);
      border-color: color(Warning, Transparent, 200);
    }
    .upper_part{
      // background: color(Warning, Transparent, 200)!important;
      background: transparent;
      z-index: 2;
      .modal_head{
        position: absolute;
        justify-content: flex-end;
        top: 0;
        opacity: 1;
        width: 100%;
        left: 0;
      }
    }
    .bottom_part{
      // display: none;
      margin: 0;
      margin-top: -60px!important;
      height: 32px;
      opacity: 0;
      padding: 0 30px!important;
      margin-top: -45px!important;
      opacity: 1;
      .center{
        width: 100%;
        button{
          width: 120px!important;
          height: 32px!important;
          font-size: 13px!important;
          padding: 0;
          &:last-child{
            margin-left: 8px!important;
          }
          div.indicator{
            padding: 2px 5px!important;
          }
        }
        .buttons_container{
          flex-wrap: nowrap!important;
          button{
            &:last-child{
              margin-top: 0!important;
            }
          }
        }
      }
    }
    // &:hover{
    //   height: 152px!important;
    //   .upper_part{
    //     // z-index: unset;
    //     animation-name: unsetZetIndex;
    //     animation-timing-function: linear;
    //     animation-delay: 500ms;
    //     animation-duration: 1ms;
    //     .modal_head{
    //       opacity: 1;
    //     }
    //     .dynamic_part{
    //       .search_progress_information{
    //         height: auto!important;
    //         .search_progress_text_container{
    //           height: auto!important;
    //         }
    //       }
    //     }
    //   }
    //   .bottom_part{
    //     margin-top: -45px!important;
    //     opacity: 1;
    //   }
    // }
    .found_back{
      opacity: 1;
    }
    @media (orientation: portrait) {
      // right: 50%!important;
      // bottom: 24px!important;
      // transform: translate(50%, 0)!important;
    }
  }
  &.low_z_index{
    z-index: 1;
  }
}

.zIndexHigh {
  z-index: 9999;
}

@keyframes unsetZetIndex {
  from {
    z-index: 2;
  } to {
    z-index: unset;
  }
}

.searching_progress_players {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 19px;
  .icon_container{
    margin-right: 10px;
    img {
      width: 40px;
      display: block;
    }
  }

  .numbers_container{
    padding: 7px;
    border-radius: 24px;
    color: color(White, Solid);
    background-color: color(Green, Transparent, 300);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 48px;
    min-height: 32px;
    span{
      font-size: 14px;
      &.player_number{
        font-weight: bold;
      }
    }
    &.disabled{
      background-color: color(Black, Transparent, 100);
    }
  }
}
