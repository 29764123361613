@font-face {
  font-family: "FiraGO_Medium";
  src: url("../public/fonts/FiraGO-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FiraGO_Light";
  src: url("../public/fonts/FiraGO-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FiraGO_Bold";
  src: url("../public/fonts/FiraGO-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FiraGO";
  src: url("../public/fonts/FiraGO-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FiraGO_SemiBold";
  src: url("../public/fonts/FiraGO-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  scrollbar-width: thin;
  scrollbar-color: grey;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
  background: rgba(37, 40, 43, 0.16);
  border-radius: 2px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  display: none;
}

*::-webkit-scrollbar-thumb {
  width: 4px;
  background: rgba(37, 40, 43, 0.16);
  border-radius: 2px;
}

:root{
  --red-player: #D22020;
  --blue-player: #143CCD;
  --green-player: #119218;
  --yellow-player: #F8C11E;

  --hole-main-stroke: #0FF5C7;
  --hole-main-shadow: 0px 0px 5px rgba(15, 245, 199, 0.64);
  --hole-home-stroke: #D1FF1B;
  --hole-home-shadow: 0px 0px 5px rgba(209, 255, 27, 0.64);
  --hole-minusFour-stroke: #AE47FF;
  --hole-minusFour-shadow: 0px 0px 5px rgba(174, 71, 255, 0.64);
}

.swal2-container{
  z-index: 9999!important;
}

.onboardingCurrentElement {
  z-index: 999;
}
.onboardingCurrentElement.hidden{
  z-index: 1;
}

/* body::-webkit-scrollbar {
  width: 1em;
} */

/*date picker custom style*/
.react-datepicker {
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  font-family: "FiraGO", sans-serif!important;
  background-color: transparent !important;
}
.react-datepicker__header{
  border-bottom: none !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-size: 18px!important;
  color: #515355!important;
  font-weight: 500!important;
}

.react-datepicker__header{
  background: transparent!important;
}

.react-datepicker .react-datepicker__day-names {
  display: none !important;
}

.react-datepicker__day,
.react-datepicker__day--keyboard-selected {
  width: 40px!important;
  height: 40px!important;
  border-radius: 50% !important;
  background: #F6F7F7 !important;
  color: #515355 !important;
  line-height: 40px!important;
  font-size: 18px;
  font-weight: bold;
  margin: 5px 8px!important;
  /* display: flex!important; */
  /* justify-content: center!important;
  align-items: center!important;
  cursor: pointer!important; */
}

.react-datepicker__day--selected {
  background: #A0BD00 !important;
  border-radius: 50% !important;
  color: white !important;
}

.react-datepicker__day:hover {
  background: #A0BD00 !important;
  color: white !important;
  border-radius: 50% !important;
}

.react-datepicker__navigation {
  background: linear-gradient(266.1deg, #FFC328 0%, #EB8F3A 100%)!important;
  cursor: pointer!important;
  border-radius: 50% !important;
  color: white!important;
  border: 2px solid #E4923C!important;
}
.react-datepicker__navigation *::before {
  border-color: white !important;
}

.react-datepicker__navigation:hover *::before {
  border-color: white !important;
}

.react-datepicker__day--disabled{
  opacity: 0.5;
}

/*date picker custom style*/


/*custom calendar*/
.custom_date_calendar  {
  padding: 8px;
  width: 100%;
}
.custom_date_calendar .react-datepicker__header{
  background-color: white;
}

.custom_date_calendar .calendar_header{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom_date_calendar .customSelect{
  width: 120px;
  position: relative;
}

.custom_date_calendar .custom_select_info{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.custom_date_calendar .custom_select_info h6 {
  color: #515355;
  font-size: 16px;
  font-weight: bold;
  /* text-transform: capitalize; */
}

.custom_date_calendar .custom_select_info .icon{
  width: 32px;
  height: 32px;
  background: rgba(37, 40, 43, 0.04);
  border-radius: 50%;
  transition: all 500ms;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom_date_calendar .custom_select_info .icon.active{
  background: rgba(35, 71, 133, 0.08);
}

.custom_date_calendar .custom_select_ul{
  position: absolute;
  height: 260px;
  border-radius: 12px;
  overflow-y: auto;
  background: white;
  list-style-type: none;
  padding: 0;
  width: 100%;
}
.custom_date_calendar .custom_select_li{
  height: 48px;
  width: 100%;
  background: transparent;
  border-radius: 12px;
  transition: all 500ms;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom_date_calendar .custom_select_li:hover{
  background: rgba(35, 71, 133, 0.04);
  color: #234785;
}


/*end of custom calendar*/
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;

  /* Fix for ios scroll screen */
  position: fixed;
  top: 0;
  overflow: hidden;
  background: radial-gradient(74.37% 74.37% at 50% 50%,#fff 0,#ebeef9 100%);
}

body {
  font-family: "FiraGO", sans-serif;
  overflow-x: hidden;
  /* background: radial-gradient(74.37% 74.37% at 50% 50%, #FFFFFF 0%, #EBEEF9 100%); */
  /* height: calc(var(--vh, 1vh) * 100); */
  height: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
}

*:focus {
  outline: none;
}

#frame {
  display: flex;
  width: 100%;
  height: calc(100vh - 75px);
  height: calc((var(--vh, 1vh) * 100) - 75px);
  align-items: center;
  justify-content: center;

  overflow: hidden;
}

#frame p {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.95em;
  position: absolute;
  top: 7.5%;
  width: 100%;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-align: center;
  user-select: none;
  text-shadow: 0 0 2px rgba(100, 100, 100, 0.5);
  z-index: 9999;
}

#table {
  position: relative;
  width: 100%;
  height: 100%;
  /* perspective: 1200px; */
  background: var(--table-color);
  box-shadow: 0 0 100px 100px var(--table-color);
}

#table .card--sample {
  top: 40%;
  left: 45%;
  transform: rotate(45deg);
}

.cards-stack {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: calc(50% + 50px);
  top: calc(50% - 75px);

  /*
  Nope =(
  margin-left: -25%;
  margin-top: -25%;
  */

  /* 
  Yep!
  */
  transform: translate(-50%, -50%);
}

.cards-stack .card {
  position: unset;
  margin-bottom: -147px;
  z-index: 2300102310;
  box-shadow: 13px 4px 10px 5px #fff;
}

.card {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 150px;
  border-radius: 10px;
  background: #f9f9f9;
  box-shadow: 1px 1px 5px 1px rgba(150, 150, 150, 0.15);
  font-size: 60px;
  background-image: url("../public/assets/icons/Back.png");
}

.card .card__symbol {
  color: #333;
}

.card .card__symbol--red {
  color: #e74c3c;
}

.hole_path {
  fill: #40d7ec;
  opacity: 0;
}

/* NEW UI */
#board{
  -webkit-font-smoothing: subpixel-antialiased;
  transition: transform 0.3s linear;
  position: relative;
  width: 100%;
  height: 100%;
  transform-origin: 50%;
  /* transform: scale(.65) translateZ(1px); */
  /* transform: scale(1); */
}
#boardBg{
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.08));
}

#boardSvg{
  transform-origin: 50%;
  -webkit-transform-origin: 50%;
}

.boardCardsMarker {
  transform: translate(303px, 272px);
}

#playersUI{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#playersUI.stealing {
  z-index: 100;
}
svg > *{
  shape-rendering: geometricPrecision;
}

/* Players strokes */
[id^="Player"] .hole_pos,
#gamePreviewSvg .hole_pos {
  fill: url(#holesGradient);
}
#gamePreviewSvg [id^="Player"] .hole_pos:not(.player_hole) {
  stroke: #00000030;
}

#boardSvg .hole_pos {
  fill: url(#holesGradient);
}

.active-marble,
.hole-highlight {
  stroke: var(--hole-main-stroke);
  filter: drop-shadow(var(--hole-main-shadow));
  stroke-width: 5px;
}
.active-marble.active-marble-seven,
.hole-highlight.active-marble-seven{
  stroke-opacity: .24;
}
.minusFour{
  stroke-width: 5px;
  stroke: var(--hole-minusFour-stroke);
  filter: drop-shadow(var(--hole-minusFour-shadow));
}
.homeHoles{
  /* filter: linear-gradient(315deg, #DD16C9 0%, #FF00E5 100%); */
  filter: drop-shadow(var(--hole-home-shadow));
  stroke: var(--hole-home-stroke);
}
.mixedBluePink{
  stroke: url(#mixedBluePinkHoles);
}
.marble-hole{
  stroke-width: 10px;
}
.active-marble.minusFour .active_hole{
  stroke: var(--hole-minusFour-stroke);
  stroke-width: 10px;
}
.kennels .active-marble.marble-hole .hole_depth,
.yellowStart.active-marble.marble-hole .hole_depth,
.redStart.active-marble.marble-hole .hole_depth,
.greenStart.active-marble.marble-hole .hole_depth,
.blueStart.active-marble.marble-hole .hole_depth,
.homes .active-marble.marble-hole .hole_depth,
.active-marble .active_hole{
  stroke-width: 10px;
  stroke: var(--hole-main-stroke);
}

.yellowStart.active-marble .hole_depth,
.redStart.active-marble .hole_depth,
.greenStart.active-marble .hole_depth,
.blueStart.active-marble .hole_depth,
.homes .active-marble .hole_depth,
.kennels .active-marble .hole_depth{
  stroke: var(--hole-main-stroke);
  stroke-width: 5px;
}

.homes .active-marble.homeHoles .hole_depth{
  /* stroke: #DD16C9; */
  stroke: var(--hole-home-stroke);
  stroke-width: 5px;
}

.yellowStart.active-marble.minusFour .hole_depth,
.redStart.active-marble.minusFour .hole_depth,
.greenStart.active-marble.minusFour .hole_depth,
.blueStart.active-marble.minusFour .hole_depth{
  stroke: var(--hole-minusFour-stroke);
  stroke-width: 5px;
}

.centeredCards{
  transition: transform .2s ease-in-out;
  transition-delay: .3s;
  position: absolute;
  z-index: 999;
}


.iconsOnCard{
  pointer-events: none;
}

/* #boardContainer.stealing #board{
  pointer-events: none;
} */

#stealingContainer{
  transform: translate(200px, 240px) scale(1);
}

/* PLAYERS DEFAULT POSITION */
.avatar_container{
  position: relative;
}




/* SPEECH BUBBLE -> Emojis */
.speech_bubble{
  background-color: #fff;
  position: absolute;
  border-radius: 16px;
  padding: 1rem;
  font-size: 1rem;
  white-space: nowrap;
  z-index: 1;
}
.speech_bubble.emoji{
  padding: .5rem;
}
.speech_bubble span,
.speech_bubble img{
  display: block;
  background: #fff;
  position: relative;  
}
.speech_bubble.bottom{
  top: -10%;
  left: 80%;
}
.speech_bubble.right{
  top: -20%;
  right: 80%;
}
.speech_bubble.top{
  bottom: -25%;
  right: 85%;
}
.speech_bubble.left{
  bottom: -25%;
  left: 85%;
}
.speech_bubble .tale{
  width: 0;
	height: 0;
	position: absolute;
  border-left: 1vh solid transparent;
	border-right: 1vh solid transparent;
	border-top: 3vh solid #fff;
}
.speech_bubble.bottom .tale{
	bottom: -15%;
	left: -15%;
	transform: rotate(45deg);
}
.speech_bubble.text.bottom .tale{
	bottom: -25%;
	left: -5%;
	transform: rotate(45deg);
}
.speech_bubble.right .tale{
	bottom: -15%;
	right: -15%;
	transform: rotate(-45deg);
}
.speech_bubble.text.right .tale{
	bottom: -25%;
	right: -5%;
	transform: rotate(-45deg);
}
.speech_bubble.top .tale{
	top: -15%;
	right: -15%;
	transform: rotate(225deg);
}
.speech_bubble.text.top .tale{
	top: -25%;
	right: -5%;
	transform: rotate(225deg);
}
.speech_bubble.left .tale{
	top: -15%;
	left: -15%;
	transform: rotate(135deg);
}
.speech_bubble.text.left .tale{
	top: -25%;
	left: -5%;
	transform: rotate(135deg);
}

.mobile .speech_bubble{
  transform: scale(1.5);
}
.mobile .speech_bubble.bottom{
  left: 110%;
  top: -40%;
}
.mobile .speech_bubble.right{
  right: 110%;
  top: -40%;
}
.mobile .speech_bubble.top{
  right: 110%;
  bottom: -45%;
}
.mobile .speech_bubble.left{
  left: 110%;
  bottom: -45%;
}

/* Players Container position */


@media only screen and (min-width: 600px) {
  /* For tablets: */
  
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  
}

@media only screen and (orientation: portrait){
  .mobile .player_loader.left{
    left: 50% !important;
    top: 150%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, 0);
  }
  .mobile .player_loader.top{
    left: 50%;
    top: 150%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, 0);
  }
  .mobile .player_loader.bottom{
    left: 50% !important;
    top: -80%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, 0);
  }
  .mobile .player_loader.right{
    left: 50%;
    top: -80%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, 0);
  }
  .mobile .playerContainer.top .progress_bar_container{
    left: -160%;
    right: unset;
    top: 40%;
  }
  .mobile .playerContainer.left .progress_bar_container{
    right: -160%;
    top: 40%;
    left: unset;
  }

  .mobile .playerContainer.bottom .progress_bar_container{
    left: unset;
    right: -100%;
    top: unset;
    bottom: 40%;
  }
  .mobile .playerContainer.right .progress_bar_container{
    left: -160%;
    right: unset;
    top: 40%;
    bottom: unset;
  }
}

/* Landscape */
@media only screen and (orientation: landscape){
  /* Desktop */
  /* #boardContainer{
    height: 70vh;
    width: 100%;
    margin-top: 0;
    margin-bottom: 20vh;
  } */
  .desktop .emojiButton,
  .desktop .joystick{
    display: none;
  }
  .mobile .emojiButton{
    transform: scale(1);
  }
  
  

  
  /* Mobile */
  @media only screen and (min-height: 800px){

  }
  
}

.playerContainer{
  transform: scale(var(--players-scale));
}
.playersCardDummySVG.bottom{
  position: fixed;
  left: 50%;
  transform: translate(-50% , -50%);
  width: 10px;
  height: 10px;
  top: 90vh;
}
.playerBottomSVG{
  transform: translate(300px, 700px);
}
.playerContainer.bottom .dice{
  left: 0;
  top: -60%;
}
.playerContainer.left .dice{
  left: 100%;
  top: -40%;
}
.playerContainer.top .dice{
  left: -10%;
  top: -40%;
}
.playerContainer.right .dice{
  left: 80%;
  top: -50%;
}

.playerContainer .progress_bar_container{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.playerContainer.top .progress_bar_container{
  right: -110%;
}
.playerContainer.bottom .progress_bar_container{
  left: -100%;
}
.playerContainer.right .progress_bar_container{
  right: -110%;
}
.playerContainer.left .progress_bar_container{
  left: -110%;
}

@media (max-width: 992px) {
  .playerContainer.top .progress_bar_container{
    left: -140%;
    right: unset;
    top: 0;
  }
  .playerContainer.left .progress_bar_container{
    right: -140%;
    top: 0;
    left: unset;
  }

  .playerContainer.bottom .progress_bar_container{
    left: unset;
    right: -90%;
    top: unset;
    bottom: 40%;
  }
  .playerContainer.right .progress_bar_container{
    left: -140%;
    right: unset;
    top: unset;
    bottom: 0;
  }
}

@media (max-width: 1024px) and (orientation: portrait){
  .playerContainer.top .progress_bar_container{
    left: -140%;
    right: unset;
    top: 0;
  }
  .playerContainer.left .progress_bar_container{
    right: -140%;
    top: 0;
    left: unset;
  }

  .playerContainer.bottom .progress_bar_container{
    left: unset;
    right: -100%;
    top: unset;
    bottom: 40%;
  }
  .playerContainer.right .progress_bar_container{
    left: -140%;
    right: unset;
    top: unset;
    bottom: 0;
  }
}

.text_bubble_chat_text::first-letter{
  /* text-transform: capitalize; */
}

.showLandscapeImage{
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 1000000;
  justify-content: center;
  align-items: center;
}

.showLandscapeImage img{
  width: 75%;
  object-fit: cover;
}

.landscapeMode {
  position: absolute;
  left: 13%;
  top: 15%;
  text-align: left;
  width: 85%;
}
.landscapeMode h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #515355;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  text-align: left;

}
.landscapeMode p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: rgba(37, 40, 43, 0.64);
  text-align: left;
  margin: 0;
  padding: 0;
}
.hideHint{
  display: none;
}

@media (orientation: landscape) {
  .showLandscapeImage{
    display: flex;
  }
}
