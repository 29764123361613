@import '../../functions';
@import '../../extends';

.notification_modal{
  @extend %pop-up-modal;
  // position: absolute;
  // left: 0;
  // top: 0;
  font-size: 16px;
  background: color(White, Solid, 500);
  width: px-to-em(320, 16);
  border-radius: px-to-em(24, 16);
  padding: 1em px-to-em(12, 16);
  cursor: default;
  .notification_modal_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .modal_title{
      font-size: px-to-em(18, 16);
      line-height: px-to-em(22, 16);
      color: color(Black, Solid, 400);
      font-weight: 300;
      // text-transform: capitalize;
    }
    .view_all_button{
      font-size: px-to-em(12, 16);
      line-height: px-to-em(14, 12);
      font-weight: bold;
      padding: px-to-em(9, 12) px-to-em(14, 12);
      border-radius: px-to-em(24, 16);
      background: color(Primary, Transparent, 50);
      color: color(Primary, Solid, 500);
      // text-transform: capitalize;
      cursor: pointer;
    }
  }
  .notifications_container{
    width: 100%;
    margin-top: px-to-em(12, 16);
    .notifications_container_ul{
      padding: 0;
      width: 100%;
      list-style-type: none;
    }
    .notifications_container_li{
      width: 100%;
      margin-top: px-to-em(4, 16);
      &:first-child{
        margin-top: 0;
      }
    }
  }
  &::before{
    border-bottom-color: color(White, Solid, 500);
  }
}