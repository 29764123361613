@import '../../functions';
@import '../../extends';

.pop_up{
  position: fixed;
  bottom: 0;
  // height: 100px;
  // min-height: 100px;
  font-size: 16px;
  background: color(White, Solid);
  padding: 1em px-to-em(24, 16) px-to-em(24, 16);
  width: 100%;
  transition: all 500ms;
  border-radius: px-to-em(24, 16) px-to-em(24, 16) 0  0;
  box-sizing: border-box;
  z-index: 998;
  .pop_up_header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .pop_up_title{
      font-size: px-to-em(18, 16);
      color: color(Black, Solid, 400);
      font-weight: 400;
      line-height: px-to-em(22, 18);
    }
  }
  &.extended{
    height: 100%;
    border-radius: 0px;
  }
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    @media (orientation: landscape) {
      height: 100%;
      .pop_up_body{
        height: 100%;
      }
    }
  }
}